import { makeStyles } from '@mui/styles';
import React, {Dispatch, RefObject, SetStateAction} from 'react';
import {LANGUAGES} from '../../config/languages';
import { MenuItem, Select } from '@mui/material';
import { logout } from '../../services/apiService';
import Fetch from '../../services/fetch';

const useStyles = makeStyles({
  selectRoot: {
    background: '#fff',
    color: 'rgba(105, 181, 186, 1) !important',

    '& > div': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },

    '& svg': {
      color: 'inherit',
    }
  }
});


const UserRow = ({userName, setUserName, timeoutId}: {userName: string, setUserName: Dispatch<SetStateAction<string | null>>, timeoutId: RefObject<number | null>}) => {
  const classes = useStyles();

  const handleLogout = async () => {
    try {
      await logout();
      Fetch.token = '';
      setUserName(null);
      localStorage.removeItem('refreshToken');
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        // @ts-ignore
        timeoutId.current = null;
      }
    } catch (e) {

    }
  };

  return (
      <Select
        className={classes.selectRoot}
        sx={{
          width: 'fit-content',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
          },
        }}
        onChange={handleLogout}
        defaultValue={LANGUAGES[0].value}
        displayEmpty
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: '#fff',
              '.MuiButtonBase-root.MuiMenuItem-root': {
                color: 'rgba(105, 181, 186, 1)',
              }
            },
          },
        }}
        renderValue={() => userName}
      >

        <MenuItem >
          Выйти
        </MenuItem>
      </Select>
  );
};

export default UserRow;