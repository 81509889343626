import Fetch from './fetch';

interface ILoginParams {
  email: string;
  password: string;
  rememberMe: boolean;
}

export enum Sender {
  Teacher = 1,
  Parent = 2,
  Student = 3,
}

export interface ISendMessageParams {
  firstName: string;
  lastName: string;
  emailAddress: string;
  message: string;
  phoneNumber: string;
  sender: Sender;
}

// account
export function login(data: ILoginParams) {
  return Fetch.post('Account/Login', data);
}

export function refresh (refreshToken: string) {
  return Fetch.post(`Account/RefreshToken/refresh-token?refreshToken=${encodeURIComponent(refreshToken)}`)
}

export function logout() {
  return Fetch.post('Account/Logout');
}

// game
export function getAllGamesByTopicId(topicId: number) {
  return Fetch.get(`Game/GetAll?topicId=${topicId}`);
}

export function getGameById(id: number) {
  return Fetch.get(`Game/Get?id=${id}`);
}

export enum GameType {
  TrueFalse = 1,
  Bubble = 2,
  RightAnswer = 3,
}

export function getGameByTypeAndTopicId(topicId: string, type: GameType) {
  return Fetch.get(`Game/GetByTopicId?topicId=${topicId}&gametype=${type}`)
}

// news
export function getAllNews() {
  return Fetch.get('News/GetAll');
}

export function getNewsById(id: number) {
  return Fetch.get(`News/Get?id=${id}`);
}

// live
export function getAllLives() {
  return Fetch.get('Live/GetAll');
}

export function getLiveById(id: number) {
  return Fetch.get(`Live/Get?id=${id}`);
}

// subject
export function getAllSubjects() {
  return Fetch.get('Subject/GetAll');
}

export function getSubjectById(id: number) {
  return Fetch.get(`Subject/Get?id=${id}`);
}

export function getSubjectsForHomePage() {
  return Fetch.get('SubjectForHomePage/GetAll');
}
// topic
export function getAllTopics(subjectId: number) {
  return Fetch.get(`Topic/GetAll?subjectId=${subjectId}`);
}

export function getTopicById(id: number) {
  return Fetch.get(`Topic/Get?id=${id}`);
}

export function searchForTopics(text: string) {
  return Fetch.get(`Topic/Search?text=${text}`);
}

export function getHomePageTopics() {
  return Fetch.get('TopicForHomePage/GetAll')
}

// contacts
export function sendMessage(data: ISendMessageParams) {
  return Fetch.post('SendEmail/SendMessage', data);
}
// user ????? //todo

