import {Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useRef,} from 'react';

import { makeStyles } from '@mui/styles';
import { AppBar, IconButton } from '@mui/material';

import PermIdentityIcon from '@mui/icons-material/PermIdentity';

import Login from '../pages/Login';
import LanguageSelectorDropdownList  from './LanguageSelectorDropdownList';
import SearchBar from './SearchBar';
import { ReactComponent as Logo } from '../images/ZhasQyran.svg';
import UserRow from './user/UserRow';
import {useLocation, useNavigate} from 'react-router-dom';
import {refresh} from "../services/apiService";
import Fetch from "../services/fetch";


const useStyles = makeStyles({
    root: {
        padding: '15px 30px',
        height: 'auto',
        position: 'unset',
        justifyContent: 'center',
        alignItems: 'center',        
        background: '#E2E2E2 !important',
      '@media (max-width: 760px)': {
        paddingInline: 15,
      }

    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      flexWrap: 'wrap',
      columnGap: 120,
      rowGap: 20,
      maxWidth: 1200,
      marginInline:'auto'
    },
    firstRow: {
      display: 'flex',
      width: 'min(420px, 45%)',
      gap: 20,
      justifyContent: 'space-between',
      alignItems: 'center',
      '@media (max-width: 760px)': {
        width: '100%'
      }
    },
  secondRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    justifyContent: 'flex-end',
    minWidth: 265,
    '@media (max-width: 760px)': {
      width: '100%',
      justifyContent: 'space-between',
    }
  },
    logo: {
      cursor: 'pointer',
      paddingInlineStart: 0,
        '&:hover': {
            backgroundColor: 'inherit !important',
        }
    },
    profileIcon: {
        color: 'rgba(105, 181, 186, 1)',
        fontSize: '1.25em !important',
    }
});

const refreshTimeInMs = 15 * 60 * 1000;

export type IHeaderProps = {
  userName: string | null;
  setUserName: Dispatch<SetStateAction<string | null>>;
  openLoginPopup: boolean;
  setOpenLoginPopup: Dispatch<SetStateAction<boolean>>;
  setIsRefreshing: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ userName, setUserName, openLoginPopup, setOpenLoginPopup, setIsRefreshing }: IHeaderProps): ReactElement => {
    const styles = useStyles();

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const timeoutId = useRef<number | null>(null);

  const [, locale] = useMemo(() => pathname.split('/'), [pathname]);

    const handleClickOpen = () => {
      setOpenLoginPopup(true);
    };  
    const handleClose = () => {
      setOpenLoginPopup(false);
    };

    const navigateToMain = () =>  {
      navigate(locale);
    }

  const handleRefresh = async(refreshToken: string) => {
    try {
      const response = await refresh(refreshToken);
      Fetch.token = response.accessToken;
      timeoutId.current = setTimeout(handleRefresh, refreshTimeInMs, response.refreshToken);
      !userName && setUserName(response.username);
      localStorage.setItem('refreshToken', response.refreshToken);
    } catch (e) {
      console.error(e);
      Fetch.token = '';
      setUserName(null);
      localStorage.removeItem('refreshToken');
    }
  }

  useEffect(() => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      setIsRefreshing(false);
      return;
    }

    handleRefresh(refreshToken).finally(() => {
      setIsRefreshing(false)
    })
  }, []);


    return (
        <AppBar className={styles.root} data-ui-name="header" position="static">
            <div className={styles.container}>
                <div className={styles.firstRow}>

                        <IconButton 
                            className={styles.logo}
                            disableRipple 
                            disableFocusRipple
                            onClick={navigateToMain}
                        >
                            <Logo />
                        </IconButton>

                  { userName && <SearchBar />}

                </div>

                <div className={styles.secondRow}>

                  {!userName && <IconButton onClick={handleClickOpen}>
                    <PermIdentityIcon className={styles.profileIcon}/>
                  </IconButton>}


                  {userName && <UserRow userName={userName} setUserName={setUserName} timeoutId={timeoutId}/>}
                   <LanguageSelectorDropdownList />
                </div>
            </div>
              {openLoginPopup && <Login handleClose={handleClose} setUserName={setUserName} handleRefresh={handleRefresh} timeoutId={timeoutId}/>}
        </AppBar>
    );
};

export default Header