import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: 36,
    borderRadius: '36px',
    textAlign: 'center',
    width: '60%',
    minWidth: 380,
    margin: '0 auto',
    '& h1': {
      color: 'rgba(230, 55, 31, 1)',
      fontSize: '35px',
    },

    '& p': {
      fontSize: '20px',
      lineHeight: '28px',
    },

    '& button': {
      marginTop: '30px',
      padding: '15px 30px',
      background: '#C03F3F',
      border: 'none',
      color: '#fff',
      borderRadius: '15px',
      minWidth: '220px',
      fontSize: '25px',
      textTransform: 'uppercase',
      boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.4)',
      cursor: 'pointer',
      '&:disabled': {
        opacity: 0.8,
      },
    },

    '@media (max-width: 644px)': {
      padding: 20,
      paddingTop: 6,
      minWidth: 300,
      borderRadius: 20,
      '& button': {
        marginTop: 10,
        padding: '10px 20px',
      },
      '& p': {
        fontSize: '16px',
        lineHeight: '22px',
      },
    }

  },
})
interface IStartSectionProps {
  text: string,
  handleStartButtonClick: () => void;
  buttonIsDisabled?: boolean;
}

const StartSection = ({text, handleStartButtonClick, buttonIsDisabled = false}: IStartSectionProps) => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <h1>{t("Правила игры:")}</h1>
      <p>{t(text)}</p>
      <button onClick={handleStartButtonClick} disabled={buttonIsDisabled}>{t("СТАРТ")}</button>
    </div>
  )
}

export default StartSection;