import React, {ReactElement, useEffect, useState} from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

import newsImg from  '../images/news_default.png';
import {getAllNews} from '../services/apiService';
import rootSlice, {INewsItem} from '../store/rootSlice';
import {useDispatch, useSelector} from 'react-redux';
import {newsSelector} from '../store/selectors';
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import BackButton from "../components/BackButton";



const useStyles = makeStyles({
  root: {
    width: '96%',
    maxWidth: 1500,
    marginInline: 'auto',
    marginBlockStart: 10,
    marginBlockEnd: 30,
    display: "grid",
    gap: 30,
  },
  grid: {
    width: '100%',
    display: "grid",
    gridTemplateColumns: 'repeat(auto-fill, 390px)',
    rowGap: 48,
    columnGap: 30,
    justifyContent: "center",
  },
  gridItem: {
    '&.MuiGrid-root': {
      width: 'fit-content',
      cursor: 'pointer'
    }
  },

  titleGrid: {
    width: '100%',
    display: "grid",
    gridTemplateColumns: 'repeat(auto-fill, 390px)',
    columnGap: 30,
    justifyContent: "center",
  },
  title: {
    '&.MuiTypography-root': {
      color: 'rgba(19, 129, 136, 1)',
      fontSize: '48px',
      lineHeight: '56px',
      fontWeight: 700,
    }
  },
  image: {
    borderRadius: 20,
    width: 390,
    height: 270,
  },
  itemTitle: {
    '&.MuiTypography-root': {
      margin: '8px 0 0',
      paddingBottom: 4,
      fontSize: '25px',
      lineHeight: '29px',
      fontWeight: 700,
      minHeight: 30,
    }
  },
  itemParagraph: {
    '&.MuiTypography-root': {
      marginBottom: '4px',
      fontSize: '18px',
      lineHeight: '24px',
    }
  },
  backButton: {
    marginInlineStart: -4,
  }
});

export const defaultNews = [
  {
    img: newsImg,
    title: 'Тема новости',
    date: '07.03.2023',
  },
];

const News = (): ReactElement => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const news = useSelector(newsSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { locale } = useParams();

  useEffect(() => {
      setLoading(true);
      getAllNews()
        .then(data => dispatch(rootSlice.actions.setNews(data)))
        .catch(e => console.error(e))
        .finally(() => setLoading(false))
  }, [locale]);

  useEffect(() => {
    return () => {
      rootSlice.actions.setNews([]);
    }
  }, [])

  const handleNavigate = (newsItem: INewsItem) => {
    navigate(String(newsItem.id));
  }

  return (
    <div className={styles.root}>
      <div className={styles.grid}>
        <BackButton classname={styles.backButton}/>
      </div>
          <div className={styles.grid}>
            <Typography className={classNames(styles.title, styles.titleGrid)}>
              {t("Новости")}
            </Typography>
          </div>


          <div className={styles.grid}>
            {news.map(newsItem => {
              const {id, title, imageUrl, createDate } = newsItem;
              return (
                <Grid key={id} item container className={styles.gridItem} flexDirection='column' onClick={() => handleNavigate(newsItem)}>
                  <img alt={title} src={imageUrl || defaultNews[0].img} className={styles.image} />
                  <Typography className={styles.itemTitle}>{title}</Typography>
                  <Typography className={styles.itemParagraph}>{createDate ? moment(createDate).format('DD.MM.YYYY'): ''}</Typography>
                </Grid>
              )
            })}
          </div>
    </div>
  );
}

export default News
