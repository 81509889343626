import React, { ReactElement } from 'react';

import { Grid, Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {ISubject} from "../store/rootSlice";

const useStyles = makeStyles({
    root: {
        '&.MuiGrid-root': {
            margin: '20px 0',
            padding: '10px 20px',
            width: 'fit-content',
            background: 'rgba(255, 246, 233, 1)',
            borderRadius: '20px',
        }
    },
    label: {
        '&.MuiTypography-root': {
            marginLeft: '15px',
            fontSize: '20px',
            lineHeight: '24px',

            '@media (max-width: 480px)': {
                fontSize: '16px',
            }
        }
    },
    icon: {
        width: 70,
        height: 70,
    }
});

export type ISubjectItemProps = {
    subject: ISubject;
    handleClick: (subject: ISubject) => void;
}

const SubjectItem = ({ subject, handleClick }: ISubjectItemProps): ReactElement => {

    const { id, title, imageUrl } = subject;

    const classes = useStyles();

    const onClick = () => {
        handleClick(subject);
    }

    return (
        <Link onClick={onClick} underline="none" component="button">
            <Grid item container alignItems="center" className={classes.root}>
                <img alt={title} src={imageUrl} className={classes.icon} />
                <Typography className={classes.label}>{title}</Typography>
            </Grid>
        </Link>
        
    );
}

export default SubjectItem