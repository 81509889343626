import {makeStyles} from '@mui/styles';
import {Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import noSearchResult from '../images/npSearchResult.svg';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {searchForTopics} from '../services/apiService';
import {ITopic} from '../store/rootSlice';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles({
  container: {
    width: 1200,
    maxWidth: '95%',
    marginInline: 'auto',
    marginBlock: 24,
    display: 'grid',
    gap: 30,
  },
  title: {
    fontSize: 20,
  },
  searchGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 242px)',
    gap: 35,

  },
  searchItem: {
    width: '100%',
    height: 271,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 20,
    filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25))',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  imageContainer: {
    width: 193,
    height: 184,
    margin: '0 auto',
    display: "grid",
    placeContent: "center"
  },
  image: {
    display: 'block',
    width: '100%',
  },
  themeDescription: {
    fontSize: 20,
    lineHeight: '27px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 'auto'
  },
  noSearchResult: {
    marginInline: 'auto'
  }

});



function Search() {
  const styles = useStyles();

  const { locale } = useParams();

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const text = searchParams.get('text');

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [searchResult, setSearchResult] = useState<ITopic[]>([]);
  const [randomTopics, setRandomTopics] = useState<ITopic[]>([]);

  useEffect(() => {
    setLoading(true)
    searchForTopics(text as string)
      .then(data => {
        const { results, randomTopics } = data;
        setSearchResult(results);
        setRandomTopics(randomTopics)
      })
      .catch(e => {
        searchResult?.length && setSearchResult([]);
        randomTopics?.length && setRandomTopics([]);
      }).finally(() => setLoading(false))
    ;
  }, [text]);

  if (loading) {
    return null;
  }


  return (
    <div className={styles.container}>
      {
        !!searchResult.length && (
          <>
            <Typography className={styles.title}>{t('Найдено по запросу:')}</Typography>
            <div className={styles.searchGrid}>
              {
                searchResult.map(({ imageUrl, title, id }) => (
                  <div key={id} className={styles.searchItem} onClick={() => navigate(`/${locale}/theme/${id}`)}>
                    <div className={styles.imageContainer}>
                      <img src={imageUrl}  className={styles.image}/>
                    </div>
                    <div className={styles.themeDescription} title={title}>
                      {title}
                    </div>
                  </div>
                ))
              }
            </div>
          </>
        )
      }
      {
        !searchResult.length && (
          <>
            <Typography className={styles.title}>{t('Извините, по Вашему запросу ничего не найдено')}</Typography>
            <img src={noSearchResult} className={styles.noSearchResult}/>
            {
              !!randomTopics.length && (
                <>
                  <Typography className={styles.title}>{t('Случайные темы:')}</Typography>
                  <div className={styles.searchGrid}>
                    {
                      randomTopics.map(({ imageUrl, title, id }) => (
                        <div key={id} className={styles.searchItem} onClick={() => navigate(`/${locale}/theme/${id}`)}>
                          <div className={styles.imageContainer}>
                            <img src={imageUrl}  className={styles.image}/>
                          </div>
                          <div className={styles.themeDescription} title={title}>
                            {title}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </>
              )
            }
          </>
        )
      }
    </div>
  )
}

export default Search;