import backIcon from "../icons/backIcon.svg";
import React from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {useNavigate, useParams} from "react-router-dom";
import cn from 'classnames';

const useStyles = makeStyles({
  backButton: {
    outline: "none",
    border: "none",
    background: "transparent",
    width: "fit-content",
    display: "flex",
    gap: 8,
    alignItems: "center",
    color: '#005F64',
    fontSize: 16,
    fontWeight: 300,
    marginInlineStart: 6,
    cursor: "pointer",
  },
})

interface IBackButtonProps {
  classname?: string;
}

function BackButton({ classname }: IBackButtonProps) {
  const { t } = useTranslation();
  const styles = useStyles();
  const { locale } = useParams();
  const navigate = useNavigate();

  return (
    <button className={cn(styles.backButton, classname)} onClick={() => navigate(`/${locale}`)}>
      <span>{t('Назад')}</span>
      <img src={backIcon} alt=""/>
    </button>
  )
}

export default BackButton;