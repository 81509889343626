import {makeStyles} from "@mui/styles";
import Apple from "../icons/apple.svg";

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    gap: 40,
    paddingTop: 25,
    paddingBottom: 15,
    alignItems: "baseline",
    '@media (max-width: 650px)': {
      paddingTop: 15,
    },
  },
  content: {
    display: "flex",
    gap: 9,
    borderBottom: '3px solid #d9d9d9',
  },
  empty: {
    flex: 1,
  },

  score: {
    fontWeight: 500,
    fontSize: 30,
    color: '#0F8086',
    flex: 1,
    lineHeight: 1
  },
  image: {
    display: 'block',
    '@media (max-width: 650px)': {
      width: 26,
      height: 26,
    },
  }

})

interface IProgressSectionProps {
  questionsCount: number;
  rightAnswersCount: number,
}

const ProgressSection = ({questionsCount, rightAnswersCount}: IProgressSectionProps) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.empty}/>
      <div className={styles.content}>
        {[...Array(questionsCount)].map((e, i) =>
          <img className={styles.image} src={Apple} alt="apples" style={{opacity: i < rightAnswersCount ? 1 : 0.5}}/>
        )}
      </div>
      <div className={styles.score}>{rightAnswersCount}/{questionsCount}</div>
    </div>
  )
}

export default ProgressSection;