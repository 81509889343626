import {Route, Routes, useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';

// Pages
import News from '../pages/News';
import Home from '../pages/Home';
import AboutUs from '../pages/AboutUs';
import Live from '../pages/Live';
import FAQ from '../pages/FAQ';
import ContactUs from '../pages/ContactUs';
import Error from '../pages/404-error';
import QuizApp from '../quiz/Quiz';
import QuizTrueFalseApp from '../quiz/QuizTrueFalse';
import QuizBubbles from '../quiz/QuizBubbles';
import Subject from '../pages/Subject';
import Theme from '../pages/Theme';
import {useEffect, useRef, useState} from 'react';
import NewsItem from "../pages/NewsItem";
import LiveItem from "../pages/LiveItem";
import Search from '../pages/Search';
import rootSlice from "../store/rootSlice";
import {useDispatch} from "react-redux";

const MainLayout = () => {

  const [userName, setUserName] = useState<string | null>(null);

  const [openLoginPopup, setOpenLoginPopup] = useState(false);

  const [isRefreshing, setIsRefreshing] = useState(true);

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/texts/ru.json`).then(data => data.json()).then(data => dispatch(rootSlice.actions.setRuJson(data)));
    fetch(`${process.env.PUBLIC_URL}/texts/kz.json`).then(data => data.json()).then(data => dispatch(rootSlice.actions.setKzJson(data)));
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
      <Header
        userName={userName}
        setUserName={setUserName}
        openLoginPopup={openLoginPopup}
        setOpenLoginPopup={setOpenLoginPopup}
        setIsRefreshing={setIsRefreshing}
      />
      <Navigation />
      {
        !isRefreshing && (
          <Routes>
            <Route path="/:locale/news" element={<News />} />
            <Route path="/:locale/news/:id" element={<NewsItem />} />
            <Route path="/:locale/about-us" element={<AboutUs />} />
            <Route path="/:locale/live" element={<Live />} />
            <Route path="/:locale/live/:id" element={<LiveItem username={userName}/>} />
            <Route path=":locale/faq" element={<FAQ />} />
            <Route path="/:locale/contact-us" element={<ContactUs />} />
            <Route path="/:locale/subject/:id" element={<Subject isLoggedIn={!!userName} setOpenLoginPopup={setOpenLoginPopup}/>} />
            <Route path="/:locale/theme/:id" element={<Theme isLoggedIn={!!userName} setOpenLoginPopup={setOpenLoginPopup}/>} />
            <Route path="/:locale" element={<Home isLoggedIn={!!userName} setOpenLoginPopup={setOpenLoginPopup}/>} />
            <Route path="/:locale/quiz" element={<QuizApp isLoggedIn={!!userName} setOpenLoginPopup={setOpenLoginPopup}/>} />
            <Route path="/:locale/quiz-true-false" element={<QuizTrueFalseApp isLoggedIn={!!userName} setOpenLoginPopup={setOpenLoginPopup}/>} />
            <Route path="/:locale/quiz-bubbles" element={<QuizBubbles isLoggedIn={!!userName} setOpenLoginPopup={setOpenLoginPopup}/>} />
            <Route path="/:locale/search" element={<Search />} />
            <Route path="*" element={<Error />} />
          </Routes>
        )
      }

      <Footer />
    </Box>
  )
}

export default MainLayout
