import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';

import theme from './constants/colors';
import './App.css';
import './i18n';

import MainLayout from './layout/MainLayout';
import { Provider } from 'react-redux';
import { store}  from './store';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <MainLayout />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  )
}

export default App
