import React, { ReactElement } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

import { ReactComponent as Logo } from '../images/ZhasQyran.svg';
import {useTranslation} from "react-i18next";

import PhoneIcon from '../icons/phone.svg';
import MailIcon from '../icons/message.svg';
import LocationIcon from '../icons/place.svg';

const useStyles = makeStyles({
    root: {
        padding: '30px 20px',
        marginTop: "auto"
    },
    footerText: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '12px',
        display: 'grid',
        gap: 12
    },

      footerItem: {
        display: "flex",
          gap: 22,
          alignItems: "center",
          fontSize: 16,
          lineHeight: 1,

}
});

const Footer = (): ReactElement => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container justifyContent="center" data-ui-name="footer" className={classes.root}>
            <Grid container maxWidth="lg" alignItems="center" justifyContent="space-evenly" rowGap={4} columnGap={4}>
                <Grid item>
                    <Logo />
                </Grid>

                <Grid item>
                    <Typography align="center" className={classes.footerText}>
                        {t("© ТОО «bilim.app»2020-2023")}
                        <br />
                        <span style={{fontSize: 18}}>{t("Все права защищены.")}</span>
                        {/*<br />*/}
                        {/*{t("г. Алматы, 20-я линия, 194")}*/}
                    </Typography>
                </Grid>
                <Grid item>
                    <div className={classes.footerText}>
                        <div className={classes.footerItem}>
                            <img src={PhoneIcon} />
                            +7707 946 33 57
                        </div>

                        <div className={classes.footerItem}>
                            <img src={MailIcon}/>
                            toobilimapp@gmail.com
                        </div>

                        <div className={classes.footerItem}>
                            <img src={LocationIcon}/>
                            {t("г. Алматы, 20-я линия, 194")}
                        </div>

                    </div>

                </Grid>
            </Grid>
        </Grid>
    );
};

export default Footer