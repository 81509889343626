import React, { ReactElement } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

import Accordions from '../components/Accordions';

import FAQBgImg from '../images/Frame.png';
import {useTranslation} from "react-i18next";
import BackButton from "../components/BackButton";

const useStyles = makeStyles({
  hbRoot: {
    padding: 30,
    maxWidth: 1000,

    aspectRatio: 2,
    backgroundImage: `url(${FAQBgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    marginBottom: 12,
  },
  faqSection: {
    padding: 30,
    background: '#E8EAE9',
  },
  buttonSection: {
    padding: 30
  },
  title: {
      marginBottom: '20px',
      color: 'rgba(19, 129, 136, 1)',
      fontSize: 48,
      lineHeight: 1.2,
      fontWeight: 700,
  },
  backButton: {
    marginInlineStart: -4,
    marginTop: -32,
  }
});

const FAQ = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" data-ui-name="faq">
      <Grid container justifyContent="center" className={classes.buttonSection}>
        <Grid container maxWidth="lg">
          <BackButton classname={classes.backButton}/>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" className={classes.hbRoot}></Grid>

      <Grid container justifyContent="center" className={classes.faqSection}>
        <Grid container maxWidth="lg">
          <Typography className={classes.title}>
            {t("Вопросы о проекте")}
          </Typography>
          <Accordions />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FAQ;
