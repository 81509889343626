import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface INewsItem {
  id: number;
  createDate: string;
  description: string;
  title: string;
  videoUrl: string;
  imageUrl: string;
}

export interface ISubject {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
}

export interface ITopic {
  id: number;
  text: string;
  title: string;
  imageUrl: string;
  videoUrl: string;
  subjectId: number;
  subjectTitle: string;
}

export interface ILive {
  id: number;
  title: string;
  description: string;
  videoUrl: string;
  startDate: string,
  endDate: string,
}

interface IInitialState {
  news: INewsItem[];
  newsItem: INewsItem | null;
  subjects: ISubject[];
  selectedSubject: ISubject | null;
  subjectTopics: ITopic[];
  homePageTopics: ITopic[];
  selectedTopic: ITopic | null;
  lives: ILive[];
  liveItem: ILive | null;
  ruJson: Record<string, any>;
  kzJson: Record<string, any>;
}
const initialState: IInitialState = {
  news: [],
  newsItem: null,
  subjects: [],
  selectedSubject: null,
  subjectTopics: [],
  homePageTopics: [],
  selectedTopic: null,
  lives: [],
  liveItem: null,
  ruJson: {},
  kzJson: {},
};



const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setNews: (state, { payload }: PayloadAction<INewsItem[]>) => {
      state.news = payload;
    },
    setNewsItem: (state, { payload }: PayloadAction<INewsItem | null>) => {
      state.newsItem = payload;
    },
    setSubjects: (state, { payload }: PayloadAction<ISubject[]>) => {
      state.subjects = payload;
    },
    setSelectedSubject: (state, { payload }: PayloadAction<ISubject | null>) => {
      state.selectedSubject = payload;
    },
    setSubjectTopics: (state, { payload }: PayloadAction<ITopic[]>) => {
      state.subjectTopics = payload;
    },
    setSelectedTopic: (state, { payload }: PayloadAction<ITopic | null>) => {
      state.selectedTopic = payload;
    },
    setHomePageTopics: (state, { payload }: PayloadAction<ITopic[]>) => {
      state.homePageTopics = payload;
    },
    setLives: (state, { payload }: PayloadAction<ILive[]>) => {
      state.lives = payload;
    },
    setLiveItem: (state, { payload }: PayloadAction<ILive | null>) => {
      state.liveItem = payload;
    },
    setRuJson: (state, { payload }: PayloadAction<Record<string, any>>) => {
      state.ruJson = payload;
    },
    setKzJson: (state, { payload }: PayloadAction<Record<string, any>>) => {
      state.kzJson = payload;
    },
  }
});

export default rootSlice;