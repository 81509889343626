export const MAIN_NAVIGATION = [
  {
    label: 'Новости',
    path: 'news',
  },
  {
    label: 'О проекте',
    path: 'about-us',
  },
  {
    label: 'Прямой эфир',
    path: 'live',
  },
  {
    label: 'FAQ',
    path: 'faq',
  },
  {
    label: 'Контакты',
    path: 'contact-us',
  },
]
