export enum LanguageCodes {
    ru = 'ru',
    kz = 'kz',
  }

  export const LANGUAGES = [
    {
      code: LanguageCodes.kz,
      value: 'KZ',
    },
    {
      code: LanguageCodes.ru,
      value: 'RU',
    }
  ];