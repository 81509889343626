import React, { ReactElement } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import HBImg from '../images/about-us-bg.png';
import {useSelector} from "react-redux";
import {kzJsonSelector, ruJsonSelector} from "../store/selectors";
import BackButton from "../components/BackButton";

const useStyles = makeStyles({
    root: {
        margin: '0 2rem 2rem',
        padding: '1rem 0 22rem',

        backgroundImage: `url(${HBImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom left',
        backgroundSize: 'contain',

        '@media (max-width: 996px)': {
            backgroundSize: 'contain',
        },
        '@media (max-width: 840px)': {
            paddingBottom: '13rem',
        },
        '@media (max-width: 600px)': {
            paddingBottom: '15rem',
        },
        '@media (max-width: 480px)': {
            paddingBottom: '12rem',
        },
        '@media (max-width: 420px)': {
            paddingBottom: '8rem',
        }
    },
    title: {
        '&.MuiTypography-root': {
            marginBottom: '20px',
            color: 'rgba(19, 129, 136, 1)',
            fontSize: '48px',
            lineHeight: '56px',
            fontWeight: 700,
        }
    },
    description: {
        '&.MuiTypography-root': {
            paddingRight: '30rem',
            fontSize: '18px',
            lineHeight: '28px',

            '@media (max-width: 996px)': {
                paddingRight: '16rem',
                fontSize: '16px',
                lineHeight: '24px',
            },
            '@media (max-width: 840px)': {
                paddingRight: '14rem', 
            },
            '@media (max-width: 600px)': {
                paddingRight: 0,
            }
        }
    },
    backButton: {
        marginInlineStart: -4,
        marginTop: -10,
        marginBottom: 14,
    }
});

export type IAbouttUsProps = {}

const AboutUs = (props: IAbouttUsProps): ReactElement => {
    const classes = useStyles();
    const { i18n: { language } } = useTranslation();

    const ruJson = useSelector(ruJsonSelector);
    const kzJson = useSelector(kzJsonSelector);

    const { about_project_title, about_project_text } = language === 'RU' ? ruJson : kzJson;

    return (
          <Grid container justifyContent="center" data-ui-name="about-us">
              <Grid container maxWidth="lg" className={classes.root}>
                  <BackButton classname={classes.backButton}/>
                  <Grid item container sm={12} xs={12}>
                      <Typography className={classes.title}>
                          {about_project_title}
                      </Typography>

                      <Typography className={classes.description}>
                          {about_project_text}
                      </Typography>
                  </Grid>
              </Grid>
          </Grid>

    );
}

export default AboutUs;
