import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import QuizImg from '../images/quiz.png';

import StartSection from "../components/StartSection";
import ScoreSection from "../components/ScoreSection";
import ProgressSection from '../components/ProgressSection';
import { useSearchParams} from "react-router-dom";
import {GameType, getGameByTypeAndTopicId} from "../services/apiService";
import QuizTitle from './QuizTitle';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
  },
  container: {
    position: 'relative',
    width: 1200,
    aspectRatio: 1/ 0.67,
    maxWidth: '100%',
    minWidth: 600,
    marginInline: 'auto',
    backgroundImage: `url(${QuizImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'contain',
  },
  backdropFilter: {
    position: 'absolute',
    inset: 0,
    backdropFilter: 'blur(4px)',
    background: 'rgba(217, 217, 217, 0.2)',
    display: 'flex',
    alignItems: 'center',
    padding: 10,
  },
  questionSection: {
    position: 'absolute',
    top: '30%',
    width: '34%',
    height: '34%',
    insetInline: 0,
    marginInline: 'auto',
    display: 'flex',
    flexDirection: 'column',

    '@media (max-width: 671px)': {
      fontSize: 13,
      width: '36%',
    }
  },

  questionText: {
    fontSize: 20,
    fontWeight: 600,
    color: "#fff",
    textAlign: 'center',
    lineHeight: 1.25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    '@media (max-width: 1000px)': {
      fontSize: 18,
    },
    '@media (max-width: 870px)': {
      fontSize: 16,
    },
    '@media (max-width: 770px)': {
      fontSize: 15,
    },
    '@media (max-width: 730px)': {
      fontSize: 14,
    },
    '@media (max-width: 671px)': {
      fontSize: 13,
    }
  },
  buttons: {
    display: 'flex',
    gap: 45,
    marginInline: 'auto',
    width: 'fit-content',
    '@media (max-width: 870px)': {
      gap: 30,
    },
    '@media (max-width: 730px)': {
      gap: 20,
    }
  },


  label: {
    display: 'grid',
    placeContent: 'center',
    minWidth: 130,
    whiteSpace: "nowrap",
    paddingInline: 10,
    height: 31,
    minHeight: 'fit-content',
    position: "relative",
    background: "#fff",
    borderRadius: 10,
    fontSize: 20,
    cursor: "pointer",
    boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.4)",
    '@media (max-width: 1153px)': {
      fontSize: 16,
    },
    '@media (max-width: 870px)': {
      minWidth: 100,
      height: 26,
      fontSize: 14,
      borderRadius: 6,
    },
    '@media (max-width: 730px)': {
      minWidth: 80,
      height: 22,
      fontSize: 12,
    },

    '& input': {
      width: "100px",
      visibility: "hidden",
      position: "absolute",
    },
    },
  right : {
    '&:has(> input:checked)': {
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4), inset 0px 0px 35px #7EFF00',
    },
  },

  wrong: {
    '&:has(> input:checked)': {
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4), inset 0px 0px 35px #FF3000',
    }
  }

});

const questions = [
  {
    question: 'What is the capital of France?',
    options: ['Paris', 'Rome'],
    answer: 'Paris'
  },
  {
    question: 'What is the tallest mammal?',
    options: ['Elephant', 'Giraffe'],
    answer: 'Giraffe'
  },
  {
    question: 'What is the currency of Japan?',
    options: ['Yen', 'Euro'],
    answer: 'Yen'
  }
];

interface IQuizAppProps {
  isLoggedIn: boolean;
  setOpenLoginPopup: Dispatch<SetStateAction<boolean>>;
}


const QuizApp = ({ isLoggedIn, setOpenLoginPopup }: IQuizAppProps) => {
  const styles = useStyles();
  const [gameStarted, setGameStarted] = useState(false);
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState<any>([])

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const classes = useStyles();

  const [searchParams] = useSearchParams();

  const getGameDataAndStartGame = () => {
    const topicId = searchParams.get('topicId');
    setLoading(true)
    getGameByTypeAndTopicId(topicId as string, GameType.RightAnswer)
      .then(data => {
        const questions = data.questions.map((item: any) => {
          return {
            question: item.text,
            options: item.answerResponseModels.map((item: any) => item.text),
            answer: item.answerResponseModels.find((item: any) => item.isRight).text,
          }
        })
        setQuestions(questions);
        questions.length && setGameStarted(true);
      })
      .catch((e) => {console.error(e)})
      .finally(() => setLoading(false))
    ;
  }

  useEffect(() => {
    if (!isLoggedIn) {
      setOpenLoginPopup(true);
      setGameStarted(false);
      setCurrentQuestion(0);
      setScore(0);
    }
  }, [isLoggedIn])

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (selectedOption) return;

    const selectedAnswer = event.target.value;
    setSelectedOption(selectedAnswer);
    if (selectedAnswer === questions[currentQuestion].answer) {
      setScore(score + 1);
    }
    setTimeout(handleNextQuestion, 700);
  };

  const handleNextQuestion = () => {

    setSelectedOption('');
    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowScore(true);
    }
  };

  const handleFinishButtonClick = () => {
    setShowScore(false);
    setGameStarted(false);
    setScore(0);
    setCurrentQuestion(0)
  };

  const handleStartButtonClick = () => {
    if (isLoggedIn) {
      getGameDataAndStartGame();
    } else {
      setOpenLoginPopup(true);
    }
  };

  return (
    <div className={styles.root}>
      <QuizTitle />
      <div className={styles.container}>
          {gameStarted ? (
            <>
              <ProgressSection questionsCount={questions.length} rightAnswersCount={score}/>
              <div
                    className={classes.questionSection}>
                <div className={classes.questionText}>{questions[currentQuestion].question}</div>

                <div className={styles.buttons}>
                  {questions[currentQuestion].options.map((option: any, index: number) => (
                    <label key={option} className={classNames(classes.label, option === questions[currentQuestion].answer ? classes.right : classes.wrong)}>
                      <input
                        type='radio'
                        value={option}
                        checked={selectedOption === option}
                        onChange={handleOptionChange}
                      />
                      {option}
                    </label>
                  ))}
                </div>
              </div>
            </>
          ) : (

            <div className={styles.backdropFilter}>
              <StartSection text="На доске указан вопрос, внизу даны два варианта ответа. Один из них не правильный. Выберите правильный ответ."
                                                                  handleStartButtonClick={handleStartButtonClick} buttonIsDisabled={loading}/>
            </div>


          )}
        {
          showScore && (
            <div className={styles.backdropFilter}>
              <ScoreSection questionsCount={questions.length} rightAnswersCount={score}
                            handleFinishButtonClick={handleFinishButtonClick}/>
            </div>

          )
        }

      </div>
    </div>

  );
}

export default QuizApp;

