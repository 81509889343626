import React, { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import QuizImg from '../images/quiz-true-false.png';
import trueIcon from '../icons/trueIcon.svg';
import falseIcon from '../icons/falseIcon.svg';
import Timer from '../icons/timer.png';
import StartSection from "../components/StartSection";
import ScoreSection from "../components/ScoreSection";
import ProgressSection from '../components/ProgressSection';
import { useSearchParams } from "react-router-dom";
import { GameType, getGameByTypeAndTopicId } from "../services/apiService";
import { useTranslation } from "react-i18next";
import QuizTitle from './QuizTitle';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
  },
  container: {
    position: 'relative',
    width: 1200,
    aspectRatio: 1/ 0.67,
    maxWidth: '100%',
    minWidth: 600,
    marginInline: 'auto',
    backgroundImage: `url(${QuizImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'contain',
  },
  backdropFilter: {
    position: 'absolute',
    inset: 0,
    backdropFilter: 'blur(4px)',
    background: 'rgba(217, 217, 217, 0.2)',
    display: 'flex',
    alignItems: 'center',
    padding: 10,
  },
  questionContainer: {
    position: 'absolute',
    inset: 0,
    width: 615,
    maxWidth: '90%',
    backgroundColor: 'rgba(255, 253, 253, 0.85)',
    height: '45%',
    '@media (max-width: 1150px)': {
      height: '50%',
    },
    '@media (max-width: 1000px)': {
      height: '56%',
    },
    '@media (max-width: 660px)': {
      height: '66%',
    },
    margin: 'auto',
    borderRadius: 20,
  },
  true: {
    backgroundColor: '#cbe9dc',
    opacity: 0.9,
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)'

},
  false: {
    backgroundColor: '#f1d4d4',
    opacity: 0.9,
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)',
  },
  question: {
    position: 'relative',
    fontSize: 20,
    lineHeight: 1.25,
    textAlign: 'center',
    paddingTop: 54,
    paddingInline: 95,
    paddingBottom: 80,
    height: '100%',
    display: "grid",
    placeContent: "center",
    '@media (max-width: 1050px)': {
      paddingBottom: 64,
    },
    '@media (max-width: 700px)': {
      paddingTop: 30,
      paddingInline: 70,
      fontSize: 18,
    },
  },
  buttons: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    width: '100%',
    paddingInline: 35,
    display: 'flex',
    justifyContent: 'space-between',

  },
  button: {
    width: 80,
    height: 80,
    background: 'transparent',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    '@media (max-width: 1050px)': {
      width: 64,
      height: 64,
    }
  },
  buttonImage: {
    width: '100%',
    height: '100%',
    display: 'block',
  },
  timer: {
    position: 'absolute',
    width: 'fit-content',
    marginInline: 'auto',
    insetInline: 0,
    bottom: '7%',
    fontSize: '35px',
    color: 'rgba(9, 89, 94, 1)',
    '@media (max-width: 700px)': {
      fontSize: '30px',
      bottom: '5%',
    }
  },
  timeLeftBackground: {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'rgba(196, 192, 192, 0.3)',
    backdropFilter: 'blur(2.5px)',
    display: 'grid',
    placeContent: 'center'
  },
  timeLeftContainer: {
    background: '#fff',
    width: 522,
    borderRadius: '20px',
    fontSize: 25,
    fontWeight: 500,
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: 25,

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: 50,
      justifyContent: 'space-around',
       // margin: '3em 1em 3em 3em',

    },
    '& button': {
      width: 220,
      height: 50,
      border: 'none',
      color: '#fff',
      backgroundColor: '#18979F',
      borderRadius: 20,
      boxShadow: 'inset 0px -4px 4px rgba(0, 0, 0, 0.25)',
      fontSize: 22,
    }
  },
});

const questions = [
  {
    question: 'Is the Paris capital of France?',
    answer: true
  },
  {
    question: 'Is the Elephant tallest mammal?',
    answer: false
  },
  {
    question: 'Is the Euro currency of Japan?',
    answer: false
  }
];

interface IQuizTrueFalseAppProps {
  isLoggedIn: boolean;
  setOpenLoginPopup: Dispatch<SetStateAction<boolean>>;
}


const QuizTrueFalseApp = ({ isLoggedIn, setOpenLoginPopup }: IQuizTrueFalseAppProps) => {
  const styles = useStyles();
  const [gameStarted, setGameStarted] = useState(false);
  const [loading, setLoading] = useState(false);

  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null)

  const [questions, setQuestions] = useState<Array<{ question: string, answer: boolean }>>([])

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);

  const [timeLeft, setTimeLeft] = useState(30);

  const [answerIsTrue, setAnswerIsTrue] = useState<boolean | null>(null);

  const [searchParams] = useSearchParams();

  const getGameDataAndStartGame = () => {
    const topicId = searchParams.get('topicId');
    setLoading(true);
    getGameByTypeAndTopicId(topicId as string, GameType.TrueFalse)
      .then(data =>{
       const questions = data.questions.map((item: any ) => ({question: item.text, answer: item.answerResponseModels[0].isRight}))
        setQuestions(questions);
       questions.length && setGameStarted(true);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }


  useEffect(() => {
    if (!isLoggedIn) {
      setOpenLoginPopup(true);
      setGameStarted(false);
      setCurrentQuestion(0);
      setScore(0);
    }
  }, [isLoggedIn])

  useEffect(() => {
    // Timer countdown
    if (gameStarted && !showScore) {
      intervalId.current = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
      if (timeLeft === 0) {
        clearInterval(intervalId.current);
        // End of game logic
        setTimeLeft(0);
        handleNextQuestion()
      }
      return () => {
        if (intervalId.current) clearInterval(intervalId.current)
      };
    } else if (showScore) {
      intervalId.current && clearInterval(intervalId.current)
    }

  }, [timeLeft, gameStarted, showScore]);

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
      setTimeLeft(30);
    } else {
      // End of game logic
      setShowScore(true);
    }
  }

  const handleAnswerClick = (answer: any) => {
    if (answer.toLowerCase() === questions[currentQuestion].answer.toString()) {
      setAnswerIsTrue(true)
      setScore(prevScore => prevScore + 1);
    } else {
      setAnswerIsTrue(false);
    }
    setTimeout(() => {
      setAnswerIsTrue(null);
      handleNextQuestion();
    }, 500)

  };

  const handleTrueClick = () => {
    handleAnswerClick('True');
  };

  const handleFalseClick = () => {
    handleAnswerClick('False');
  };

  const handleFinishButtonClick = () => {
    setGameStarted(false);
    setShowScore(false);
    setScore(0);
    setTimeLeft(30);
    setCurrentQuestion(0)
  };


  const handleStartButtonClick = async() => {
    if (isLoggedIn) {
      getGameDataAndStartGame();
    } else {
      setOpenLoginPopup(true);
    }
  };

  return (
    <div className={styles.root}>
      <QuizTitle />
      <div className={styles.container}>
        {gameStarted ?
              showScore ? (
                  <div className={styles.backdropFilter}>
                    <ScoreSection questionsCount={questions.length} rightAnswersCount={score} handleFinishButtonClick={handleFinishButtonClick} />
                  </div>
              ):
          <>
            <ProgressSection questionsCount={questions.length} rightAnswersCount={score}/>
            {
              questions.length > 0 && (
                <div className={classNames(styles.questionContainer, {
                  [styles.true]: answerIsTrue === true,
                  [styles.false]: answerIsTrue === false,
                })}>
                  <div className={styles.question}>{questions[currentQuestion].question}</div>
                  <div className={styles.buttons}>
                    <button className={styles.button} onClick={handleTrueClick} disabled={answerIsTrue !== null}>
                      <img src={trueIcon} className={styles.buttonImage} alt="true-button"/>
                    </button>
                    <button className={styles.button} onClick={handleFalseClick} disabled={answerIsTrue !== null}>
                      <img src={falseIcon} className={styles.buttonImage} alt="false-button"/>
                    </button>
                  </div>
                </div>
              )
            }
            <div className={styles.timer}>Таймер: {timeLeft} секунд</div>
          </>

             : (
              <div className={styles.backdropFilter}>
                <StartSection text="Перед вами представлен какой то факт. По двум краям карточки находятся кнопки “верно” или “не верно”. На каждый вопрос даётся 30 секунд."
                              handleStartButtonClick={handleStartButtonClick} buttonIsDisabled={loading}/>
              </div>

            )}

      </div>
    </div>
  );
}

export default QuizTrueFalseApp;

