import { makeStyles } from '@mui/styles';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { Typography } from '@mui/material';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    gap: 12,
    alignItems: 'baseline',
    width: 1200,
    maxWidth: '100%',
    minWidth: 600,
    marginInline: 'auto',
    marginBottom: 30,
    '@media (max-width: 1200px)': {
      paddingInlineStart: 10,
    }
  },
  topic: {
    cursor: 'pointer',
    display: 'flex',
    gap: 12,
    alignItems: 'baseline'
  },
  titleLink: {
    fontWeight: 700,
    fontSize: 30,
    color: '#0F8086',
  },
  titleText: {
    fontSize: 20,
  },
})

function QuizTitle(){
  const styles = useStyles();

  const [searchParams] = useSearchParams();
  const { locale } = useParams();
  const { t } = useTranslation();

  const handleGoBack = () => {
    const topicId = searchParams.get('topicId');
    navigate(`/${locale}/theme/${topicId}`);
  }

  const navigate = useNavigate();

  const title = useMemo(() => {
    const topicTitle = searchParams.get('topicTitle');
    return topicTitle && decodeURIComponent(topicTitle)},
    [searchParams]);

  return (
    <>
      { title ?
        <div className={styles.title}>
          <div className={styles.topic} onClick={handleGoBack}>
            <Typography className={styles.titleLink}>{title}</Typography>
            <Typography className={styles.titleLink}>{'>'}</Typography>
          </div>
          <Typography className={styles.titleText}>{t('Игры')}</Typography>
        </div> : null
      }
    </>
  )
}

export default QuizTitle