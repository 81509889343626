import { RootState } from './index';

export const newsSelector = (state: RootState) => state.news;

export const newsItemSelector = (state: RootState) => state.newsItem;

export const subjectsSelector = (state: RootState) => state.subjects;

export const selectedSubjectSelector = (state: RootState) => state.selectedSubject;

export const subjectTopicsSelector = (state: RootState) => state.subjectTopics;

export const homePageTopicsSelector = (state: RootState) => state.homePageTopics;

export const selectedTopicSelector = (state: RootState) => state.selectedTopic;

export const livesSelector = (state: RootState) => state.lives;

export const liveItemSelector = (state: RootState) => state.liveItem;

export const ruJsonSelector = (state: RootState) => state.ruJson;

export const kzJsonSelector = (state: RootState) => state.kzJson;