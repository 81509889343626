import React, {ChangeEvent, ReactElement, useEffect, useRef, useState} from 'react';
import { createPortal } from 'react-dom';
import {makeStyles} from '@mui/styles';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';

import PhoneIcon from '../icons/phone.svg';
import MailIcon from '../icons/message.svg';
import LocationIcon from '../icons/place.svg';
import {ISendMessageParams, Sender, sendMessage} from "../services/apiService";
import {useTranslation} from "react-i18next";
import BackButton from "../components/BackButton";

const useStyles = makeStyles({
    root: {
        margin: '3rem 2rem 10rem',
        padding: '30px 20px',
        boxShadow: '0px 0px 15px 0px rgb(0 0 0 / 25%)',
        borderRadius: '20px',

        '& > div:first-child': {
            padding: '0 10px',
            borderRight: '1px solid rgba(15, 128, 134, 1)',
        },
        '& > div:last-child': {
            padding: '0 30px',
            position: 'relative',

            '& > div:first-child': {
                marginTop: '50px',
            }
        }
    },
    title: {
        '&.MuiTypography-root': {
          color: 'rgba(19, 129, 136, 1)',
          fontSize: '48px',
          lineHeight: '56px',
          fontWeight: 700,
        }
    },
    item: {
        flexWrap: "nowrap",
        '&.MuiGrid-root': {
            margin: '30px 0',
        }
    },
    label: {
        '&.MuiTypography-root': {
          marginLeft: '35px',
          fontSize: '20px',
          lineHeight: '28px',
        }
    },
    inputItem: {
        '&.MuiGrid-root': {
            margin: '16px 0',
            '& > div': {
                width: '45%',
            } 
        }
    },
    inputMsgItem: {
        '&.MuiGrid-root': {
            margin: '16px',
            '& > div': {
                width: '100%',
            },
        }
    },
    submitBtn: {
        '&.MuiGrid-root': {
            '& .MuiButtonBase-root.MuiButton-root': {
                padding: '6px 50px',
                borderRadius: '15px',
                textTransform: 'initial',
                fontSize: '20px',
                marginInlineStart: 'auto',
                
                '&:hover, &:active, &:focus': {
                    background: 'rgba(15, 128, 134, 1)',
                    color: '#fff'
                }
            }
        }
    },
    radioGroup: {
        '& .MuiFormControl-root': {
            margin: '30px 16px'
        },
        '& .MuiFormGroup-root': {
            flexDirection: 'row',
            justifyContent: 'space-between'
        }
    },
    sendResult: {
        fontWeight: 600,
        fontSize: 18,
    },
    error: {
        color: '#d32f2f',
    },
    success: {
        color: '#2FB176',
    },
    modalBackground: {
        position: "fixed",
        inset: 0,
        background: 'rgba(202, 202, 202, 0.5)',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    modalContent: {
        width: 500,
        maxWidth: '90%',
        paddingBlock: 40,
        paddingInline: 20,
        background: '#fff',
        borderRadius: 20,
        display: 'grid',
        gap: 20,
        textAlign: "center",
    },

    modalSuccessTitle: {
        fontWeight: 500,
        fontSize: 30,
        color: '#138188',
    },

    modalErrorTitle: {
        fontWeight: 500,
        fontSize: 30,
        color: '#CA4751'
    },

    modalText: {
        fontWeight: 300,
        fontSize: 25
    }
});

interface IErrors {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    msg?: string;
}

export type IContactUsProps = {}

const ContactUs = (props: IContactUsProps): ReactElement => {
    const { t } = useTranslation();
    const styles = useStyles();
    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        msg: '',
        sender: "Student"
    });
    const [errors, setErrors] = useState<IErrors>({
    });

    const [loading, setLoading] = useState(false);

    const [sendResult, setSendResult] = useState<'error' | 'success' | null>(null);

    const validateValues = () => {
        let errors: IErrors = {};
        if(!state.firstName) {
            errors.firstName = "Заполните Имя";
        }

        if(!state.firstName) {
            errors.lastName = "Заполните Фамилию";
        }

        if(!state.email) {
            errors.email = "Заполните поле";
        }

        if(!state.phoneNumber) {
            errors.phoneNumber = "Заполните поле";
        }

        if(!state.msg) {
            errors.msg = "Заполните поле";
        }

        if(state.email && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(state.email)) {
            errors.email = "Не корректно введен Email"
        }

        if(state.phoneNumber && !/^\+?77(\d{9})$/i.test(state.phoneNumber)) {
            errors.phoneNumber = "Не корректно введен номер телефона"
        }

        setErrors(errors);

        return !Object.keys(errors).length;

    };

    const handleChange = async (field: any, value: any) => {
        setState({
            ...state,
            [field]: value
        });
    }

    const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

    const handleSubmit = () => {
        const isValid = validateValues();
        if (!isValid) {
            return;
        }
        const { firstName, lastName, email, msg, phoneNumber, sender } = state;

        const data: ISendMessageParams = {
            emailAddress: email,
            //subject: sender,
            message: msg,
            firstName,
            lastName,
            phoneNumber,
            // @ts-ignore
            sender: Sender[sender],
        }
        setLoading(true);
        sendResult && setSendResult(null);
        sendMessage(data)
          .then(() => {
              setSendResult('success');
          })
          .catch(e => {
              setSendResult('error');
          })
          .finally(() => {
              setLoading(false);
              timeoutId.current = setTimeout(() => setSendResult(null), 5000)
          });
    }

    useEffect(() => {
        return () => {
            timeoutId.current && clearTimeout(timeoutId.current);
        }
    }, []);

    return (
        <Grid container justifyContent="center" data-ui-name="contact-us" >
            <Grid container maxWidth="lg"  alignItems='start' style={{paddingInline: 20, maxWidth: 1260, marginBottom: -20}}>
                <BackButton />
            </Grid>


            <Grid container maxWidth="lg" className={styles.root} alignItems='baseline'>
                <Grid item container md={5} sm={6} xs={12}>
                    <Typography className={styles.title}>
                        {t("Контакты")}
                    </Typography>

                    <Grid className={styles.item} container item xs={12} alignItems="center">
                        <img src={PhoneIcon} alt="Phone" width={35} height={35}/>
                        <Typography className={styles.label}>+7707 946 33 57</Typography>
                    </Grid>

                    <Grid className={styles.item} container item xs={12} alignItems="center">
                        <img src={MailIcon} alt="E-mail" width={35} height={35}/>
                        <Typography className={styles.label}>toobilimapp@gmail.com</Typography>
                    </Grid>

                    <Grid className={styles.item} container item xs={12} alignItems="center">
                        <img src={LocationIcon} alt="Location" width={35} height={35}/>
                        <Typography className={styles.label}>{t("г. Алматы, 20-я линия, 194")}</Typography>
                    </Grid>
                </Grid>

                <Grid item container md={7} sm={6} xs={12} alignContent="flex-start">
                    <Grid className={styles.inputItem} item container xs={12} flexDirection="row" justifyContent="space-around">
                        <TextField 
                            id="firstName" 
                            label={t("Имя")}
                            variant="standard" 
                            type="text"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange("firstName", e.target.value)}
                            helperText={errors.firstName ? t(errors.firstName) : ""}
                            error={!!errors.firstName}
                        />
                        <TextField 
                            id="lastName" 
                            label={t("Фамилия")}
                            variant="standard" 
                            type="text"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange("lastName", e.target.value)}
                            helperText={errors.lastName ? t(errors.lastName) : ""}
                            error={!!errors.lastName}
                        />
                    </Grid>

                    <Grid className={styles.inputItem} item container xs={12} flexDirection="row" justifyContent="space-around">
                        <TextField 
                            id="e-mail" 
                            label="E-mail"
                            variant="standard" 
                            type="email"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange("email", e.target.value)}
                            helperText={errors.email ? t(errors.email) : ""}
                            error={!!errors.email}
                        />
                        <TextField 
                            id="phoneNumber" 
                            label={t("Номер телефона")}
                            variant="standard"
                            type="phone"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange("phoneNumber", e.target.value)}
                            helperText={errors.phoneNumber ? t(errors.phoneNumber) : ""}
                            error={!!errors.phoneNumber}
                            placeholder="+77999999999"
                        />
                    </Grid>

                    <Grid className={styles.inputMsgItem} item container xs={12}>
                        <TextField
                            id="msg"
                            label={t("Сообщение")}
                            placeholder={t("Напишите свое сообщение...") as string}
                            multiline
                            variant="standard"
                            type="text"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange("msg", e.target.value)}
                            helperText={errors.msg ? t(errors.msg) : ""}
                            error={!!errors.msg}
                        />
                    </Grid>

                    <Grid className={styles.radioGroup} container item xs={12}>
                        <FormControl>
                            <FormLabel>{t("От кого сообщение")}?</FormLabel>
                                <RadioGroup
                                    value={state.sender}
                                    onChange={(e: any) => handleChange("sender", e.target.value)}
                                >
                                    <FormControlLabel value="Teacher" control={<Radio />} label={t("Учитель")} />
                                    <FormControlLabel value="Student" control={<Radio />} label={t("Ученик")} />
                                    <FormControlLabel value="Parent" control={<Radio />} label={t("Родитель")} />
                                </RadioGroup>
                        </FormControl>
                    </Grid>
                    { sendResult &&
                        <>
                            {createPortal(<div className={styles.modalBackground} onClick={() => {
                                setSendResult(null);
                                clearTimeout(timeoutId.current!);
                                timeoutId.current = null;
                            }}>
                                <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                                    {
                                        sendResult === 'success' ? (
                                          <>
                                              <Typography className={styles.modalSuccessTitle}>Спасибо за обратную связь!</Typography>
                                              <Typography className={styles.modalText}>Ваше письмо доставлено</Typography>
                                          </>) :
                                          (<>
                                              <Typography className={styles.modalErrorTitle}>Произошла ошибка!</Typography>
                                              <Typography className={styles.modalText}>Исправьте и попробуйте отправить
                                                  заново</Typography>
                                          </>
                                        )
                                    }

                                </div>
                            </div>, document.body!)}
                        </>

                    }

                    <Grid className={styles.submitBtn} item container xs={12} alignItems="baseline">
                        <Button variant="outlined" onClick={handleSubmit} disabled={loading}>
                            {t("Отправить")}
                        </Button>
                    </Grid>                   
                </Grid>                
            </Grid>
        </Grid>
    );
};

export default ContactUs
