import { useDispatch, useSelector } from "react-redux";
import {liveItemSelector } from "../store/selectors";
import React, {useEffect, useRef, useState} from "react";
import { getLiveById } from "../services/apiService";
import { useParams } from "react-router-dom";
import rootSlice from "../store/rootSlice";
import { makeStyles } from "@mui/styles";
import Chat from "../components/Chat";
import {defaultNews} from "./News";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    width: '96%',
    maxWidth: 1050,
    marginInline: 'auto',
    marginBottom: 30,
  },
  title: {
    fontWeight: 700,
    fontSize: 30,
    marginBottom: 10,
    marginTop: 30,
  },
  date: {
    fontWeight: 300,
    fontSize: 16,
    marginBottom: 30,
  },
  description: {
    fontSize: 20,
    marginBottom: 25,
  },
  image: {
    display: "block",
    width: '100%',
  },
  chatWrapper: {
    height: 600,
    border: '1px solid #138188',
    borderRadius: 20,
    paddingInline: 16,
    display: "flex",
    flexDirection: "column",
  },
  chatTitle: {
    fontWeight: 500,
    fontSize: 25,
    color: '#177D8A',
    height: 54,
    borderBottom: '1px solid #138188',
    paddingInlineStart: 22,
    paddingTop: 12,
  },
  chatContent: {
    flex: 1,
    overflow: "auto",
    display: "grid",
    gap: 30,
    paddingBlock: 30,
    paddingInlineStart: 8,
    fontSize: 20,
  },
  chatItem: {
    height: "fit-content",
    display: "grid",
    gridTemplateColumns: '50px auto 1fr',
    gap: 16,
    alignItems: "center",
  },
  chatCircle: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    background: 'orange',
    color: '#fff',
    display: "grid",
    placeContent: "center",
  },
  chatUsername: {
    color: '#666666',
    whiteSpace: "nowrap",
  },
  chatMessage: {
  },

  chatFooter: {
    borderTop: '1px solid #138188',
    paddingBlock: 26,
    paddingInlineStart: 4,
  },
  chatInputGroup: {
    display: "grid",
    gridTemplateColumns: '50px 1fr auto',
    gap: 16,
    marginTop: 10,

  },
  chatInput: {
    border: "none",
    outline: "none",
    borderBottom: '1px solid #138188',
    fontSize: 17,

  },
  sendButton: {
    marginInlineStart: 16,
    padding: 0,
    border: "none",
    outline: "none",
    cursor: "pointer",
  },
  sendIcon: {
    display: "block"
  }

})

interface ILiveItemProps {
  username: string | null;
}

function LiveItem({username}: ILiveItemProps) {

  const liveItem = useSelector(liveItemSelector);
  const intervalId = useRef<ReturnType <typeof setInterval> | null>(null)

  const dispatch = useDispatch();
  const { id } = useParams();

  const styles = useStyles();

  const [loading, setLoading] = useState(false);

  const { title, description, startDate, videoUrl, endDate } = liveItem || {};

 // const endDate = "2023-06-17T19:58:32.4109371"

  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
      setLoading(true);
      getLiveById(Number(id))
        .then(data => dispatch(rootSlice.actions.setLiveItem(data)))
        .catch(e => console.error(e))
        .finally(() => setLoading(false));

    return () => {
      dispatch(rootSlice.actions.setLiveItem(null));
    }
  }, []);


  useEffect(() => {
    if (liveItem && username) {
      const start = new Date(startDate as string).getTime();
      const end = new Date(endDate as string).getTime();
      const now = new Date().getTime();
      setShowChat(now >= start && now < end);
      if (now < end) {
        intervalId.current = setInterval(() => {
          const now = new Date().getTime();
          console.log(start, end, now)
          setShowChat(now >= start && now < end);
          if (now > end) {
            intervalId.current && clearInterval(intervalId.current);
            intervalId.current = null;
          }
        }, 60 * 1000)
      } else {
        if (intervalId.current) {
          clearInterval(intervalId.current);
          intervalId.current = null;
        }
      }
    }
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
    }
  }, [liveItem, username])


  if (loading) return null;

  return (
    <div className={styles.root}>
      {
        liveItem && (
          <>
            {videoUrl ? <iframe style={{aspectRatio: '1 / 0.6'}} width='100%'
                                src={videoUrl.includes('https') ?videoUrl : `https://${videoUrl}`}
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen/>: <img alt={title} src={defaultNews[0].img} />}
            <div className={styles.title}>{title}</div>
            <div className={styles.date}>{moment(startDate).format('DD.MM.YY, HH:mm')}</div>
            <div className={styles.description}>{description}</div>

          </>
        )
      }
      {username && showChat && <Chat username={username}/>}
    </div>
  )
}

export default LiveItem;