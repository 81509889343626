import { useDispatch, useSelector } from "react-redux";
import { newsItemSelector } from "../store/selectors";
import React, { useEffect, useState } from "react";
import { getNewsById } from "../services/apiService";
import { useParams } from "react-router-dom";
import rootSlice from "../store/rootSlice";
import {makeStyles} from "@mui/styles";
import moment from "moment";
import {defaultNews} from "./News";

const useStyles = makeStyles({
  root: {
    width: '96%',
    maxWidth: 1200,
    marginInline: 'auto',
    marginBottom: 30,
  },
  title: {
    fontWeight: 700,
    fontSize: 30,
    marginBottom: 15,
  },
  date: {
    fontWeight: 300,
    fontSize: 16,
    marginBottom: 30,
  },
  description: {
    fontSize: 20,
    marginBottom: 25,
  },
  image: {
    display: "block",
    width: '100%',
    maxWidth: 500,
    marginBottom: 20,
    //borderRadius: 20,
  }
})

function NewsItem() {

  const newsItem = useSelector(newsItemSelector);

  const dispatch = useDispatch();
  const { id, locale } = useParams();

  const styles = useStyles();

  const [loading, setLoading] = useState(false);

  const { title, description, videoUrl, createDate, imageUrl } = newsItem || {}

  useEffect(() => {
      setLoading(true);
      getNewsById(Number(id))
        .then(data => dispatch(rootSlice.actions.setNewsItem(data)))
        .catch(e => console.error(e))
        .finally(() => setLoading(false))
  }, [locale])

  useEffect(() => {
    return () => {
      dispatch(rootSlice.actions.setNewsItem(null));
    }
  }, [])

  return (
    <div className={styles.root}>
      {
        newsItem && (
          <>
            <img alt={title} src={imageUrl ?? defaultNews[0].img} className={styles.image} />
            <div className={styles.title}>{title}</div>
            <div className={styles.date}>{moment(createDate).format('DD.MM.YY')}</div>
            <div className={styles.description}>{description}</div>
            {videoUrl && <iframe style={{aspectRatio: '1 / 0.6'}} width='100%'
                                 src={videoUrl.includes('https') ?videoUrl : `https://${videoUrl}`}
                     title="YouTube video player" frameBorder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                     allowFullScreen/>}
          </>
        )
      }
    </div>
  )
}

export default NewsItem;