import React, { ReactElement } from 'react';
import { Grid, Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    card: {
        padding: '20px',
        background: '#fff',
        border: '3px solid #5C5CB5',
        borderRadius: '10px',
        boxShadow: 'rgba(17, 17, 26, 0.1) 0px 0px 16px',
        height: 256,
    },
    cardTitle: {
        '&.MuiTypography-root': {
            fontSize: '20px',
            lineHeight: '23px',
            marginBottom: -10,
        }
    },
    cardDescription: {
      '&.MuiTypography-root': {
        paddingTop: 10,
        fontSize: 15,
        lineHeight: '23px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 6,
        overflow: 'hidden',

      }

    },
    cardLink: {
        '&.MuiTypography-root': {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            color: '#000',
            textAlign: 'right',
            fontSize: '15px',
            fontWeight: '300',
            textDecoration: 'none',
            cursor: 'pointer',

            '& svg': {
                marginLeft: '6px',
                fontSize: '13px'
            }
        }
    }
});

export type ITopicCardProps = {
  id: number;
  title: string;
  text: string;
  handleTopicClick: (id: number) => void;
}

const TopicCard = ({ id, title, text, handleTopicClick }: ITopicCardProps): ReactElement => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid item container className={classes.card}>
            <Typography className={classes.cardTitle}>{title}</Typography>
          <div>
            <Typography className={classes.cardDescription}>{text}</Typography>
          </div>

            <Link className={classes.cardLink} onClick={() => handleTopicClick(id)}>
                {t("Подробнее")}
                <ArrowForwardIosIcon fontSize="small" />
            </Link>
        </Grid>
    );
}

export default TopicCard