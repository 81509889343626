import React, {ReactElement, useCallback, useMemo, useState} from 'react';
import {useLocation, useNavigate } from 'react-router-dom';

import { Grid, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import bilimSvg from '../images/bilim.svg';
import bilimHoverSvg from '../images/bilimhover.svg';

import { MAIN_NAVIGATION } from '../constants/mainNavigation';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    root: {
        padding: '0 30px',
        '@media (max-width: 760px)': {
            paddingInline: 15,
        }

    },
    menuList: {
        display: 'flex',
        alignItems: "center",
        flexDirection: 'row',

        '& li:first-child': {
            '& > div': {
                paddingLeft: '0 !important',
                '@media (max-width: 600px)': {
                    padding: '15px 0 !important',
                }
            }            
        },

        '@media (max-width: 730px)': {
            flexWrap: 'wrap',

            '& li': {
                '& > *': {
                    padding: '15px 0 !important',
                },

            },
        }
    },
    menuItem: {
        padding: '15px 22.5px !important',

        '&:hover': {
            backgroundColor: 'inherit !important',

            '& span': {
                borderBottom: '1px solid #000',
            }          
        },

        '& span': {
            width: 'max-content',
            fontSize: '15px',
            lineHeight: '18px',
            borderBottom: '1px solid transparent',
        }
    },
    activeMenuItem: {
        '& span': {
            borderBottom: '1px solid #000',
        }
    }
});

const Navigation = (): ReactElement => {
    const classes = useStyles();

    const { t } = useTranslation();

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [, locale, currentPath] = useMemo(() => pathname.split('/'), [pathname]);

    const navigateToPath = useCallback(
        (path: string) => () => {
            let pathTo = locale + '/' + path;

            navigate(pathTo);
        },
        [locale],
    );

    const [hovered, setHovered] = useState(false);

    return (
        <Grid container justifyContent="center" data-ui-name="navigation" className={classes.root}>
            <Grid container maxWidth="lg">
                <List className={classes.menuList}>
                    {MAIN_NAVIGATION.map(({ label, path }, index) => {
                        const active = path === currentPath;

                        return (
                            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton 
                                    onClick={navigateToPath(path)}
                                    className={classNames(classes.menuItem, active ? classes.activeMenuItem : '')}
                                >
                                    <ListItemText primary={t(label)} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                    <ListItem  disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                          onClick={() => {}}
                          className={classes.menuItem}
                          onMouseEnter={() => setHovered(true)}
                          onMouseLeave={() => setHovered(false)}
                          href="https://bilim-app.kz/login"
                        >
                            <img src={hovered ? bilimHoverSvg : bilimSvg} alt=""/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
}
  
export default Navigation

