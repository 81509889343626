import React, {ChangeEvent, ReactElement, useState, KeyboardEvent, MouseEvent, useRef, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import { InputBase } from '@mui/material';
import searchIcon from '../images/searchIcon.svg';
import searchIconGreen from '../images/searchIconGreen.svg';
import deleteIcon from '../images/deleteIcon.svg';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import Fetch from '../services/fetch';
import useDidUpdateEffect from '../hooks/useDidUpdateEffect';
import {set} from 'lodash';

const Search = styled('div')(() => ({
  marginLeft: 0,
  width: '250px !important',
  flexShrink: 0,
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '30px',
  '@media (max-width: 450px)': {
    width: '200px !important'
  },
}));

const SearchIconWrapper = styled('div')(() => ({
  height: '100%',
  width: 45,
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    transform:' translateX(3px)',
    '& svg': {
      color: 'rgba(105, 181, 186, 1)',
    },
  }
}));

const DeleteIconWrapper = styled('div')(() => ({
  height: '100%',
  width: 38,
  position: 'absolute',
  right: 0,
  top: 0,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#EEEDED',
  zIndex: 2,
  '& img': {
    transform:' translateX(-2px)'
  }
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: 60,
    paddingRight: 50,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: 'rgba(105, 181, 186, 1)',
    '@media (max-width: 450px)': {
      width: 100
    },
  },
}));

const SearchBar = (): ReactElement => {
  const { t } = useTranslation();


  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [value, setValue] = useState('');

  const [focused, setFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const text = searchParams.get('text');
    if (text !== value) {
      setValue(text || '')
    }
  }, [searchParams])

  useDidUpdateEffect(() => {
    if (!pathname.includes('search')) {
      setValue('')
    }
  }, [pathname])


  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const appliedValue = value.trim();
      if(appliedValue) {
        navigate(`${Fetch.language}/search?text=${appliedValue}`);
      }
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
   setValue(e.target.value);
  }

  return (
      <Search style={{backgroundColor: focused ? '#fff': 'rgba(255, 255, 255, 0.6)'}}>
          <SearchIconWrapper style={{backgroundColor: focused ? '#1699A1': 'transparent'}}>
              <img src={focused ? searchIcon: searchIconGreen}/>
          </SearchIconWrapper>
        {
           !!value.length && focused && (
             <DeleteIconWrapper onMouseDown={(e: MouseEvent<HTMLDivElement>) => {
               e.preventDefault()
               setValue('');
               inputRef.current?.focus();
            }}>
              <img src={deleteIcon}/>
            </DeleteIconWrapper>
          )
        }
          <StyledInputBase
              inputRef={inputRef}
              placeholder={`${t('Поиск')}...`}
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              value={value}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
          />

      </Search>
  );
}

export default SearchBar
