import smile from "../images/smile.svg";
import noSmile from "../images/noSmile.svg";
import { makeStyles } from "@mui/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  root: (props: Record<string, string>) =>({
    display: 'flex',
    alignItems: 'center',
    '--background-color': props.type === 'success' ? '#30CA84' : '#E45353',
    '--box-shadow': props.type === 'success'
      ? '0px 0px 20px rgba(0, 0, 0, 0.25), inset 0px -4px 4px rgba(0, 0, 0, 0.25)'
      : '0px 0px 20px rgba(0, 0, 0, 0.25), inset 0px -4px 4px rgba(0, 0, 0, 0.25)'
}),
  button: {
    border: 'none',
    color: '#ffffff',
    width: 262,
    height: 56,
    fontWeight: 500,
    fontSize: 25,
    borderRadius: 20,
    backgroundColor: 'var(--background-color)',
    boxShadow: 'var(--box-shadow)',
  },
  image: {
    display: 'block',
    transform: 'translateX(-40px)'
  }
})

interface IResultButtonProps {
  type: 'success' | 'error';
}
const ResultButton = ({ type }: IResultButtonProps) => {
  const { t } = useTranslation();
  const styles = useStyles({type});

  return (
    <div className={styles.root}>
      <button className={styles.button}>{t(type === 'success' ? 'Верно!' : 'Не верно')}</button>
      <img src={type === 'success' ? smile: noSmile} alt={type === 'success' ? 'smile': 'no-smile'} className={styles.image}/>
    </div>
  )
}

export default ResultButton;