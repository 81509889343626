import {makeStyles} from '@mui/styles';
import QuizImg from '../images/quiz.png';
import QuizTrueFalseImg from '../images/quiz-true-false.png';
import QuizBubbleImg from "../images/quiz-bubbles.png";
import GirlImage from '../images/quiz-girl.svg';
import StartSection from '../components/StartSection';
import React, {Dispatch, SetStateAction, useEffect} from 'react';
import Carousel from 'react-material-ui-carousel'
import {useDispatch, useSelector} from "react-redux";
import {selectedTopicSelector} from "../store/selectors";
import {useNavigate, useParams} from "react-router-dom";
import {GameType, getTopicById} from "../services/apiService";
import rootSlice from "../store/rootSlice";
import useDidUpdateEffect from "../hooks/useDidUpdateEffect";
import {Typography} from '@mui/material';

const useStyles = makeStyles({
  container: {
    width: '100%',
    maxWidth: 1200,
    paddingInline: 15,
    margin: '0 auto',
    display: 'grid',
    gap: 50,
  },
  description: {
    display: 'grid',
    gap: 30,
    fontSize: 20,
    maxWidth: 1160
  },
  title: {
    display: 'flex',
    gap: 12,
    alignItems: 'baseline'
  },
  subject: {
    cursor: 'pointer',
    display: 'flex',
    gap: 12,
    alignItems: 'baseline'
  },
  titleLink: {
    fontWeight: 700,
    fontSize: 30,
    color: '#0F8086',
  },
  titleText: {
    fontSize: 20,
  },

  mediaContainer: {
    display: "flex",
    flexWrap: 'wrap',
    gap: 30,
    justifyContent: "space-between",
  },
  media: {
    display: "block",
    width: 500,
    maxWidth: '100%',
    aspectRatio: 1/0.6,
    borderRadius: 20,
    // '@media (max-width: 530px)': {
    //   marginTop: 15,
    // },
  },
  carousel: {
    width: '100%',
    height: '100%',
  },
  carouselItem: {
    margin: '0 auto',
    position: 'relative',
    width: '100%',
    maxWidth: 1045,
  },
  carouselImage: {
    display: 'block',
    width: '100%'
  },
  girlImage: {
    width: '14.5%',
    position: 'absolute',
    left: '8%',
    bottom: '16%',
  },
  background: {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'rgba(217, 217, 217, 0.2)',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    alignItems: 'center',
  }
});

const routesPerGameType = {
  [GameType.RightAnswer]: 'quiz',
  [GameType.TrueFalse]: 'quiz-true-false',
  [GameType.Bubble]: 'quiz-bubbles'
}

interface IThemeProps {
  isLoggedIn: boolean;
  setOpenLoginPopup: Dispatch<SetStateAction<boolean>>
}

const Theme = ({ isLoggedIn, setOpenLoginPopup }: IThemeProps) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const selectedTopic = useSelector(selectedTopicSelector);
  const dispatch = useDispatch();
  const { id, locale } = useParams();

  const { imageUrl, title, text, videoUrl, subjectTitle, subjectId } = selectedTopic || {};

  const getPageData = () => {
    getTopicById(Number(id))
      .then(data => dispatch(rootSlice.actions.setSelectedTopic(data)))
      .catch(e => console.error(e));
  }

  useEffect(() => {
    if (!isLoggedIn) {
      setOpenLoginPopup(true);
    } else  {
     getPageData();
    }
  }, [isLoggedIn])

  useDidUpdateEffect(() => {
    isLoggedIn && getPageData();
  }, [locale])

  if (!selectedTopic) {
    return null;
  }

  const handleStartGame = (gameType: GameType) =>  {
    navigate(`/${locale}/${routesPerGameType[gameType]}?topicId=${id}&topicTitle=${encodeURIComponent(title as string)}`)
  }

  const handleGoBack = () =>  {
    navigate(`/${locale}/subject/${subjectId}`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <div className={styles.title}>
          <div className={styles.subject} onClick={handleGoBack}>
            <Typography className={styles.titleLink}>{subjectTitle}</Typography>
            <Typography className={styles.titleLink}>{'>'}</Typography>
          </div>
          <Typography className={styles.titleText}>{title}</Typography>
        </div>
        <div className={styles.mediaContainer}>
          <img src={imageUrl} alt={title} className={styles.media}/>
          {videoUrl && <iframe className={styles.media} src={videoUrl.includes('https') ?videoUrl : `https://${videoUrl}`}
                               title="YouTube video player" frameBorder="0"
                               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                               allowFullScreen/>}
        </div>
        <div>
          {text}
        </div>


      </div>
      <div className={styles.carousel}>
        <Carousel animation="slide" duration={500} indicatorIconButtonProps={
          {
            style: {
              border: '1px solid #FE7C00',
              color: 'transparent',
            }

          }
        }
                  activeIndicatorIconButtonProps={{
                    style: {
                      backgroundColor: 'rgba(254, 124, 0, 0.8)'
                    }

                  }}
                  indicatorContainerProps={{
                    style: {
                      display: 'flex',
                      gap: 44,
                      justifyContent: 'center',
                      marginTop: 30,
                    }
                  }}>
          <div className={styles.carouselItem}>
            <img src={QuizImg} className={styles.carouselImage} alt="" />
            <div className={styles.background}>
              <StartSection text='На доске указан вопрос, внизу даны два варианта ответа. Один из них не правильный. Выберите правильный ответ.'
                            handleStartButtonClick={() => {handleStartGame(GameType.RightAnswer)}} />

            </div>

          </div>
          <div className={styles.carouselItem} >
            <img src={QuizTrueFalseImg} className={styles.carouselImage} alt=""/>
            <div className={styles.background}>
              <StartSection text="Перед вами представлен какой то факт. По двум краям карточки находятся кнопки “верно” или “не верно”. На каждый вопрос даётся 30 секунд."
                            handleStartButtonClick={() => {handleStartGame(GameType.TrueFalse)}} />
            </div>

          </div>
          <div className={styles.carouselItem}>
            <img src={QuizBubbleImg} className={styles.carouselImage} alt=""/>
            <img src={GirlImage} className={styles.girlImage} alt=""/>
            <div className={styles.background}>
              <StartSection text='Будет задан вопрос и несколько вариантов  ответа в пузырях.
 Надо лопнуть лишние пузыри.' handleStartButtonClick={() => {handleStartGame(GameType.Bubble)}} />
            </div>

          </div>
        </Carousel>


      </div>
    </div>
  )
}

export default Theme;