import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Carousel } from '../components/Carousel';
import { HBSlider } from '../components/HBSlider';
import Accordions from '../components/Accordions';
import TopicCard from '../components/TopicCard';
import SubjectItem from '../components/SubjectItem';

import TopicsBgImg from '../images/topics_bg.png';
import {Dispatch, SetStateAction, useCallback, useEffect} from 'react';
import {getAllSubjects, getHomePageTopics, getSubjectsForHomePage} from '../services/apiService';
import {useDispatch, useSelector} from "react-redux";
import rootSlice, {ISubject, ITopic} from "../store/rootSlice";
import {homePageTopicsSelector, subjectsSelector} from "../store/selectors";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  sectionTitle: {
    '&.MuiTypography-root': {
      marginBottom: '20px',
      color: 'rgba(19, 129, 136, 1)',
      fontSize: '48px',
      lineHeight: '56px',
      fontWeight: 700,
      paddingInlineStart: 48,
    }
  },
  subjectSection: {
    padding: '30px',
  },
  faqSection: {
      padding: '30px',
      background: '#E8EAE9',
  },
  topicsSection: {
    padding: '30px',
    backgroundImage: `url(${TopicsBgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
  }
});

const topicContainerWidthsByCount: Record<number, number> = {
  1: 280,
  2: 2 * 280,
  3: 3 * 260,
  4: 4 * 260,
};

interface IHomeProps {
  isLoggedIn: boolean;
  setOpenLoginPopup: Dispatch<SetStateAction<boolean>>;
}

const Home = ({ isLoggedIn, setOpenLoginPopup }: IHomeProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { locale } = useParams();

  const navigate = useNavigate();

  const subjects = useSelector(subjectsSelector);

  const topics = useSelector(homePageTopicsSelector);


  useEffect(() => {
    if (isLoggedIn) {
      getAllSubjects().then(data => dispatch(rootSlice.actions.setSubjects(data))).catch(e => console.error(e));
    } else {
      getSubjectsForHomePage().then(data => dispatch(rootSlice.actions.setSubjects(data))).catch(e => console.error(e));
    }
  }, [isLoggedIn, locale, dispatch])

  useEffect(() => {
    getHomePageTopics().then(data => dispatch(rootSlice.actions.setHomePageTopics(data))).catch(e => console.error(e));
  }, [locale, dispatch])

  const goToSubjectPage = useCallback((subject: ISubject) => {
    if (isLoggedIn) {
      dispatch(rootSlice.actions.setSelectedSubject(subject));
      navigate(`subject/${subject.id}`);
    } else {
      setOpenLoginPopup(true);
    }
  }, [isLoggedIn, dispatch, navigate, setOpenLoginPopup]);

  const handleTopicClick = (id: number) => {
    if (isLoggedIn) {
      navigate(`/${locale}/theme/${id}`);
    } else {
      setOpenLoginPopup(true);
    }
  }

  return (
    <Grid container justifyContent="center" data-ui-name="home">
      <Grid container>
        <HBSlider />
      </Grid>

      <Grid container justifyContent="center" className={classes.subjectSection}>
        <Grid container maxWidth="lg">
          <Typography className={classes.sectionTitle}>
            {t("Предметы")}
          </Typography>

          <Grid container justifyContent="space-evenly">
            {subjects.slice(0, 7).map(subject => (
                <SubjectItem key={subject.id} handleClick={goToSubjectPage} subject={subject} />
              )
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" className={classes.faqSection}>
        <Grid container maxWidth="lg">
          <Typography className={classes.sectionTitle}>
            {t("Вопросы о проекте")}
          </Typography>
          <Accordions />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" className={classes.topicsSection}>
        <Grid container maxWidth="lg" flexDirection="column">
          <Typography className={classes.sectionTitle}>
            {t("Темы")}
          </Typography>
          <div style={{width: topicContainerWidthsByCount[topics.length] || '100%', maxWidth: '100%'}}>
            <Carousel>
              {topics.map(({ id, title, text }: ITopic) => (
                <TopicCard key={id} id={id} title={title} text={text} handleTopicClick={handleTopicClick} />
              ))}
            </Carousel>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Home
