import React, {ReactElement, useEffect} from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { getAllLives } from "../services/apiService";
import rootSlice from "../store/rootSlice";
import { useDispatch, useSelector } from "react-redux";
import {livesSelector} from "../store/selectors";
import moment from "moment";
import BackButton from "../components/BackButton";

const useStyles = makeStyles({
  root: {
    width: '96%',
    maxWidth: 1500,
    marginInline: 'auto',
    marginBlock: 30,
    display: "grid",
    gap: 30,
  },
  grid: {
    width: '100%',
    display: "grid",
    gridTemplateColumns: 'repeat(auto-fill, 390px)',
    rowGap: 48,
    columnGap: 30,
    justifyContent: "center",
  },
  gridItem: {
    '&.MuiGrid-root': {
      width: 'fit-content',
    }
  },

  titleGrid: {
    width: '100%',
    display: "grid",
    gridTemplateColumns: 'repeat(auto-fill, 390px)',
    columnGap: 30,
    justifyContent: "center",
  },
  title: {
    '&.MuiTypography-root': {
      color: 'rgba(19, 129, 136, 1)',
      fontSize: '48px',
      lineHeight: '56px',
      fontWeight: 700,
    }
  },
  itemTitle: {
    '&.MuiTypography-root': {
      margin: '8px 0 0',
      fontSize: '25px',
      fontWeight: 700,
      paddingBottom: 4,
      cursor: 'pointer',
    }
  },
  itemParagraph: {
    '&.MuiTypography-root': {
      paddingBottom: 4,
      fontSize: '18px',
      cursor: 'pointer',
    }
  },
  backButton: {
    marginInlineStart: -4,
    marginTop: -20,
  }
});

const Live = (): ReactElement => {
  const styles = useStyles();
  const navigate = useNavigate();
  const lives = useSelector(livesSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllLives()
      .then(data => dispatch(rootSlice.actions.setLives(data)))
      .catch((e) => console.error(e));

    return () => {
      dispatch(rootSlice.actions.setLives([]));
    }
  }, [])

  const handleNavigate = (id: number) => {
    navigate(String(id));
  }

  return (
    <div className={styles.root}>
      <div className={styles.grid}>
        <BackButton classname={styles.backButton}/>
      </div>
      <div className={styles.grid}>
        <Typography className={classNames(styles.title, styles.titleGrid)}>
          Прямые эфиры
        </Typography>
      </div>

          <div className={styles.grid}>
            {lives.map(({title, id, videoUrl, startDate}) => (
              <Grid key={id} item container className={styles.gridItem} flexDirection='column'>
                <iframe width="390" height="270"
                        src={videoUrl.includes('https') ?videoUrl : `https://${videoUrl}`}
                        style={{borderRadius: 20}}
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
                {/*<img alt={live.title} src={live.img} />*/}
                <Typography className={styles.itemTitle} onClick={() => handleNavigate(id)}>{title}</Typography>
                <Typography className={styles.itemParagraph} onClick={() => handleNavigate(id)}>{moment(startDate).format('DD.MM.YYYY')}</Typography>
                <Typography className={styles.itemParagraph} onClick={() => handleNavigate(id)}>{moment(startDate).format('HH:mm')}</Typography>
              </Grid>
            ))}
          </div>
    </div>
  );
}

export default Live
