import { makeStyles } from "@mui/styles";
import QuizImg from "../images/quiz-bubbles.png";
import GirlImage from '../images/quiz-girl.svg';
import Bubble from "../components/Bubble";
import ScoreSection from "../components/ScoreSection";
import StartSection from "../components/StartSection";
import ProgressSection from "../components/ProgressSection";
import ResultButton from '../components/ResultButton';
import {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {GameType, getGameByTypeAndTopicId} from "../services/apiService";
import { useSearchParams } from 'react-router-dom'
import QuizTitle from './QuizTitle';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
  },
  container: {
    position: 'relative',
    width: 1200,
    aspectRatio: 1/ 0.67,
    maxWidth: '100%',
    minWidth: 600,
    marginInline: 'auto',
    backgroundImage: `url(${QuizImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'contain',
  },
  question: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 20,
    '@media (max-width: 900px)': {
      fontSize: 16
    },
  },
  backdropFilter: {
    position: 'absolute',
    inset: 0,
    backdropFilter: 'blur(4px)',
    background: 'rgba(217, 217, 217, 0.2)',
    display: 'flex',
    alignItems: 'center',
    padding: 10,
  },
  errorBackdropFilter: {
    position: 'absolute',
    inset: 0,
    backdropFilter: 'blur(2px)',
    background: 'rgba(240, 146, 146, 0.2)',
    display: "grid",
    placeContent: "center",
  },
  successBackdropFilter: {
    position: 'absolute',
    inset: 0,
    backdropFilter: 'blur(2px)',
    background: 'rgba(97, 192, 149, 0.2)',
    display: "grid",
    placeContent: "center",
  },
  hiddenImage: {
    visibility: 'hidden',
    width: '100%'
  },
  girlImage: {
    width: '14.5%',
    position: 'absolute',
    left: '8%',
    bottom: '16%',
  },
  bubble1: {
    top: '26%',
    left: '26%',
  },
  bubble2: {
    top: '34%',
    left: '50%',
  },
  bubble3: {
    top: '27%',
    left: '78%',
  },
  bubble4: {
    top: '59%',
    left: '34%',
  },
  bubble5: {
    top: '53%',
    left: '67%',
  },
});

interface IBubble {
  id: number;
  isRight: boolean;
  text: string;
}

interface IBubbleProps {
  data: IBubble[];
  setResult: Dispatch<SetStateAction<'success' | 'error' | null>>;
  setScore: Dispatch<SetStateAction<number>>;
  handleNext: () => void;
}

const Bubbles = ({ data, setResult, setScore, handleNext } : IBubbleProps ) => {
  const styles = useStyles();

  const wrongAnswers = useRef(data.filter((item) => !item.isRight))

  const handleClick = (item: IBubble) => {
    if (!item.isRight) {
      wrongAnswers.current = wrongAnswers.current.filter(wrongAnswer => wrongAnswer.id !== item.id);
      if (!wrongAnswers.current.length) {
        setResult('success');
        setScore(prev => prev + 1);
        setTimeout(() => {
          setResult(null);
          handleNext();
        }, 1000)
      }
    } else {
      setResult('error');
      setTimeout(() => {
        setResult(null);
        handleNext();
      }, 1000)
    }
  }

  return (
    <>
      {
        data.length && (
          <>
            <Bubble text={data[0].text} onClick={() =>{handleClick(data[0])}} className={styles.bubble1} />
            <Bubble text={data[1].text} onClick={() =>{handleClick(data[1])}} className={styles.bubble2} />
            <Bubble text={data[2].text} onClick={() =>{handleClick(data[2])}} className={styles.bubble3} />
            <Bubble text={data[3].text} onClick={() =>{handleClick(data[3])}} className={styles.bubble4} />
            <Bubble text={data[4].text} onClick={() =>{handleClick(data[4])}} className={styles.bubble5} />
          </>
        )
     }

    </>
  )
}

interface IQuizBubbleProps {
  isLoggedIn: boolean;
  setOpenLoginPopup: Dispatch<SetStateAction<boolean>>;
}

const QuizBubbles = ({ isLoggedIn, setOpenLoginPopup }: IQuizBubbleProps) => {
  const styles = useStyles();

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const [gameStarted, setGameStarted] = useState(false);

  const [showScore, setShowScore] = useState(false);

  const [score, setScore] = useState(0);

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [ questions, setQuestions] = useState<any[]>([]);

  const [result, setResult] = useState<'success' | 'error' | null>(null);

const getGameDataAndStartGame = () => {
  const topicId = searchParams.get('topicId');
  setLoading(true);
  getGameByTypeAndTopicId(topicId as string, GameType.Bubble)
    .then((data) => {
      setQuestions(data.questions);
      data.questions.length && setGameStarted(true);
    })
    .catch(e => console.error(e))
    .finally(() => setLoading(false));
}

  useEffect(() => {
    if (!isLoggedIn) {
      setOpenLoginPopup(true);
      setGameStarted(false);
      setScore(0);
      setCurrentQuestion(0);
    }
  }, [isLoggedIn]);

  const handleStartButtonClick = () => {
    if (isLoggedIn) {
      getGameDataAndStartGame()
    } else {
      setOpenLoginPopup(true);
    }
  }

  const handleNext = () => {
    if (questions[currentQuestion + 1]) {
      setCurrentQuestion(prev => prev + 1);
    } else {
      setShowScore(true)
    }
  }


  return (
    <div className={styles.root}>
      <QuizTitle />

      <div className={styles.container}>
        <img src={GirlImage} className={styles.girlImage} alt=""/>
        {(!gameStarted || showScore)  && <div className={styles.backdropFilter}>

          {!gameStarted && <StartSection text="Будет задан вопрос и несколько вариантов  ответа в пузырях. Надо лопнуть лишние пузыри." handleStartButtonClick={handleStartButtonClick} buttonIsDisabled={loading}/>}
          {showScore && <ScoreSection questionsCount={questions.length} rightAnswersCount={score} handleFinishButtonClick={() => {
            setShowScore(false);
            setGameStarted(false);
            setCurrentQuestion(0);
            setScore(0)
          }}/>}
        </div>}

        {
          gameStarted && !showScore && (
            <>
              <ProgressSection questionsCount={questions.length} rightAnswersCount={score}/>
              <div className={styles.question}>{questions[currentQuestion].text}</div>
              <Bubbles key={questions[currentQuestion].id} data={questions[currentQuestion].answerResponseModels} setResult={setResult} setScore={setScore} handleNext={handleNext}/>
            </>
          )
        }


        {
          result === 'success' && (
            <div className={styles.successBackdropFilter}>
              <ResultButton type="success" />
            </div>
          )
        }
        {
          result === 'error' && (
            <div className={styles.errorBackdropFilter}>
              <ResultButton type="error" />
            </div>
          )
        }
      </div>
    </div>
  )
}

export default QuizBubbles;