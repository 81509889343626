import bubble1 from '../images/bubble1.svg';
import bubble2 from '../images/bubble2.svg';
import bubble3 from '../images/bubble3.svg';
import {makeStyles} from "@mui/styles";
import classNames from "classnames";
import { useState} from "react";

const useStyles = makeStyles({
  imageContainer: {
    position: 'absolute',
    width: '13%',
    aspectRatio :' 1 / 1',
    display: 'grid',
    placeContent: 'center',
    isolation: 'isolate',
    cursor: "pointer"
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    transition: 'opacity 700ms',
  },
  option: {
    zIndex: 1,
    transition: 'opacity 800ms',
    textAlign: "center",
    '@media (max-width: 900px)': {
      fontSize: 14
    },
    '@media (max-width: 800px)': {
      fontSize: 12,
      fontWeight: 500,
    },

  }
})

interface IBubbleProps {
  text: string;
  className: string;
  onClick: () => void;
}

const Bubble = ({text, className, onClick}: IBubbleProps) => {

  const [bubble1Opacity, setBubble1Opacity] = useState(1);
  const [bubble2Opacity, setBubble2Opacity] = useState(1);
  const [bubble3Opacity, setBubble3Opacity] = useState(1);

  const handleClick = () => {
     setBubble1Opacity(0);
     setTimeout(() => {
       setBubble2Opacity(0);
     }, 200);
    setTimeout(() => {
      setBubble3Opacity(0);
    }, 550)
    onClick();
  }

  const styles = useStyles();
  return (
    <div className={classNames(styles.imageContainer, className)} onClick={handleClick} >
      <img src={bubble3} className={styles.image} style={{opacity: bubble3Opacity}} alt=''/>
      <img src={bubble2} className={styles.image} style={{opacity: bubble2Opacity}} alt=''/>
      <img src={bubble1} className={styles.image} style={{opacity: bubble1Opacity}} alt=''/>
      <span className={styles.option} style={{opacity: bubble1Opacity}}>{text}</span>
    </div>
  )
}

export default Bubble;