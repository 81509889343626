import React, {Dispatch, SetStateAction, useEffect} from 'react'
import { makeStyles } from '@mui/styles';
import SubjectImg from '../images/subject.png';
import backIcon from '../icons/backIcon.svg';
import {useDispatch, useSelector} from "react-redux";
import {selectedSubjectSelector, subjectTopicsSelector} from "../store/selectors";
import {useNavigate, useParams} from "react-router-dom";
import {getAllTopics, getSubjectById} from "../services/apiService";
import rootSlice from "../store/rootSlice";
import {useTranslation} from "react-i18next";
import useDidUpdateEffect from "../hooks/useDidUpdateEffect";
import BackButton from "../components/BackButton";

const useStyles = makeStyles({
  container: {
    width: '100%',
    maxWidth: 1200,
    paddingInline: 15,
    margin: '0 auto',
    display: 'grid',
    gap: 50,
  },
  backButton: {
    marginBottom: -30,
  },
  descriptionContainer: {
    height: 350,
    overflow: 'hidden',
    borderRadius: 20,
    filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25))',
  },
  description: {
    height: 370,
    transform: 'translateY(-6px)',
    backgroundImage: `url(${SubjectImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    paddingTop: 30,
    paddingInlineStart: 50,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    fontSize: 60,
    fontWeight: 600,
    color: '#0F8086',
    '@media (max-width: 590px)': {
      fontSize: 50,
    },
    '@media (max-width: 520px)': {
      fontSize: 40,
    },
    '@media (max-width: 430px)': {
      fontSize: 30,
    },
    '@media (max-width: 380px)': {
      fontSize: 24,
    },
  },
  descriptionImage: {
    width: 116,
    height: 116,
    '@media (max-width: 590px)': {
      width: 100,
      height: 100,
    },
    '@media (max-width: 520px)': {
      width: 80,
      height: 80,
    },
    '@media (max-width: 430px)': {
      width: 60,
      height: 60,
    },
  },
  text: {
    maxWidth: 700,
    fontSize: 20,
    lineHeight: 1.25,
    marginTop: 30,
    letterSpacing: 1.05,
    paddingRight: 20,
    '@media (max-width: 430px)': {
      marginTop: 15,
    },
  },
  sliderContainer: {
    borderRadius: 20,
    backgroundColor: '#f3f3f2',
    //paddingInline: 40,
    paddingBlock: 30,
  },
  slider: {
    width: '100%',
  },
  sliderTitle: {
    color:'#0F8086',
    fontWeight: 700,
    fontSize: 30,
    paddingInline: 40,
    //marginBottom: 30,
  },
  sliderContent: {
    paddingTop: 30,
    paddingInline: 40,
    display: 'grid',
    rowGap: 80,
    columnGap: 40,
    gridTemplateColumns: 'repeat(auto-fill, minmax(235px, 1fr))',
    justifyContent: 'space-between',
  },
  sliderItem: {
    backgroundColor: '#ffffff',
    height: 271,
    paddingBlock: 20,
    paddingInline: 24,
    borderRadius: 20,
    display: 'grid',
    gap: 20,
    filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25))',
    cursor: 'pointer',
  },
  imageContainer: {
    width: 193,
    height: 184,
    margin: '0 auto',
    display: "grid",
    placeContent: "center"
  },
  image: {
    display: 'block',
    width: '100%',
  },
  themeDescription: {
    fontSize: 20,
    lineHeight: '27px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

})

interface ISubjectProps {
  isLoggedIn: boolean;
  setOpenLoginPopup: Dispatch<SetStateAction<boolean>>
}

const Subject = ({ isLoggedIn, setOpenLoginPopup }: ISubjectProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const selectedSubject = useSelector(selectedSubjectSelector);
  const topics = useSelector(subjectTopicsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, locale } = useParams();

  const { title, description, imageUrl } = selectedSubject || {};

  const getPageData = () => {
    getSubjectById(Number(id))
      .then(data => dispatch(rootSlice.actions.setSelectedSubject(data)))
      .catch(e => console.error(e));

    getAllTopics(Number(id))
      .then(data => dispatch(rootSlice.actions.setSubjectTopics(data)))
      .catch(e => console.error(e));
  }

  useEffect(() => {
    if (!isLoggedIn) {
      setOpenLoginPopup(true);
    } else {
      getPageData()
    }
  }, [isLoggedIn]);

  useDidUpdateEffect(() => {
    isLoggedIn && getPageData();
  }, [locale])

  useEffect(() => {
    return () => {
      dispatch(rootSlice.actions.setSelectedSubject(null));
      dispatch(rootSlice.actions.setSubjectTopics([]));
    }
  }, [])

  if (!selectedSubject) {
    return null;
  }

  return (
    <div className={styles.container}>
      <BackButton classname={styles.backButton} />
      <div className={styles.descriptionContainer}>
        <div className={styles.description}>
          <div className={styles.title}>
            <img src={imageUrl} alt="" className={styles.descriptionImage}/>
            <span>{title}</span>
          </div>
          <div className={styles.text}>
            {description}
          </div>
        </div>
      </div>
      {
        topics.length > 0 &&
        (
          <div className={styles.sliderContainer}>
            <div className={styles.sliderTitle}>
              {t("Темы по предмету")}
            </div>
            <div className={styles.sliderContent}>
              {
                topics.map((topic) => {
                  const { id, imageUrl, title } = topic;
                  return (
                    <div className={styles.sliderItem} onClick={() => {
                      //dispatch(rootSlice.actions.setSelectedTopic(topic));
                      navigate(`/${locale}/theme/${id}`);
                    }}>
                      <div className={styles.imageContainer}>
                        <img src={imageUrl}  className={styles.image} alt=""/>
                      </div>
                      <div className={styles.themeDescription} title={title}>
                        {title}
                      </div>

                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Subject
