import { BASE_URL } from '../constants/urls';

enum HTTP_METHOD  {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

enum CONTENT_TYPE  {
  applicationJson = 'application/json',
  formData = '',
}

const HEADER_TYPE = {
  contentType: 'Content-Type',
  timeZone: 'TimeZone',
  authorization: 'Authorization',
  acceptLanguage: 'Accept-Language',
}

export const errorHandler = (response :any) => {
  //handling unauthorized, forbidden, not-found ,  maintenance and backend errors
  if (!response.ok) {
    throw new Error(`${response.status}`);
  }
  return response;
};

function buildHeaders(
                      acceptLanguage: string,
                      contentType = CONTENT_TYPE.applicationJson,
                      includeTimeZone = true,
                      token: string,
                      additionalHeaders?: Headers
) {
  let headers = {};

  // if (includeTimeZone) {
  //     headers = {
  //         [HEADER_TYPES.timeZone]: (new Date().getTimezoneOffset() / (-60)).toString(),
  //         ...headers
  //     };
  // }

  if (token && token.length > 0) {
    headers = {
      [HEADER_TYPE.authorization]: `Bearer ${token}`,
      ...headers
    };
  }

  switch (contentType) {
    case CONTENT_TYPE.applicationJson:
      headers = {
        [HEADER_TYPE.contentType]: CONTENT_TYPE.applicationJson,
        ...headers
      };
      break;
    case CONTENT_TYPE.formData:
      // content type should be empty for this type
      break;
  }

  if(additionalHeaders){
    headers = {
      ...headers,
      ...additionalHeaders
    }
  }

  headers = {
    ...headers,
    [HEADER_TYPE.acceptLanguage] : acceptLanguage
  }

  return headers;
}

class Fetch {
  static token = '';
  static language = 'ru';

  static get(url: string, signal?: AbortSignal, contentType = CONTENT_TYPE.applicationJson) {
    return this.request(url, HTTP_METHOD.GET, null, contentType, signal);
  }


  static post(url: string, data?: Record<string, any>, contentType =  CONTENT_TYPE.applicationJson, signal?: AbortSignal) {
    return this.request(url, HTTP_METHOD.POST, data, contentType, signal);
  }


  static async request(url: string, method: HTTP_METHOD, data: Record<string, any> | null | undefined, contentType: CONTENT_TYPE, signal?: AbortSignal) {
    const headers = buildHeaders(this.language, contentType, true, this.token);
    const body = method !== HTTP_METHOD.GET ? contentType === CONTENT_TYPE.applicationJson ? JSON.stringify(data) : data : null;

    const response = await this.uniqueRequest(url, method, headers as Headers, body as BodyInit, signal);
    if (url !== 'Account/Logout') {
      return response.clone().json();
    }
  }

  static async uniqueRequest(url: string, method: HTTP_METHOD, headers: Headers, body: BodyInit | null | undefined, signal?: AbortSignal) {
    //this method will be used to make requests unique
    const response = await fetch(`${BASE_URL}/${url}`, {
    method,
      headers,
      body,
      signal,
  });
  return errorHandler(response);
}
}

export default Fetch;

