import React, {FormEvent, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import sendIcon from '../icons/Subtract.svg'
import {useTranslation} from "react-i18next";
import {WS_URL} from "../constants/urls";
import * as signalR from "@microsoft/signalr"
import {HubConnection, HubConnectionState} from "@microsoft/signalr"

const useStyles = makeStyles({
  chatWrapper: {
    height: 600,
    border: '1px solid #138188',
    borderRadius: 20,
    paddingInline: 16,
    display: "flex",
    flexDirection: "column",
  },
  chatTitle: {
    fontWeight: 500,
    fontSize: 25,
    color: '#177D8A',
    height: 54,
    borderBottom: '1px solid #138188',
    paddingInlineStart: 22,
    paddingTop: 12,
  },
  chatContent: {
    flex: 1,
    overflow: "auto",
    display: "grid",
    alignContent: "start",
    alignItems: "center",
    gridTemplateColumns: '50px auto 1fr',
    rowGap: 30,
    columnGap: 16,
    paddingBlock: 30,
    paddingInlineStart: 8,
    fontSize: 20,
  },
  chatItem: {
    height: "fit-content",
    display: "grid",
    gridTemplateColumns: '50px auto 1fr',
    gap: 16,
    alignItems: "center",
  },
  chatCircle: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    background: 'orange',
    color: '#fff',
    display: "grid",
    placeContent: "center",
  },
  chatUsername: {
    color: '#666666',
    whiteSpace: "nowrap",
  },
  chatMessage: {
  },

  chatFooter: {
    borderTop: '1px solid #138188',
    paddingBlock: 26,
    paddingInlineStart: 4,
  },
  chatInputGroup: {
    display: "grid",
    gridTemplateColumns: '50px 1fr auto',
    gap: 16,
    marginTop: 10,

  },
  chatInput: {
    border: "none",
    outline: "none",
    borderBottom: '1px solid #138188',
    fontSize: 17,

  },
  sendButton: {
    marginInlineStart: 16,
    padding: 0,
    border: "none",
    outline: "none",
    cursor: "pointer",
    background: "transparent",
    '&:disabled': {
      opacity: 0.5
    }
  },
  sendIcon: {
    display: "block"
  }

})

const colors = ['#FC6C3C', '#6D9469', '#35A6DE', '#E367EE'];

let colorsByUsername = {} as Record<string, string>;
let currentColorIndex = 1;

interface ILiveItemProps {
  username: string;
}

function Chat({username}: ILiveItemProps) {

  const { t } = useTranslation();

  const { id } = useParams();

  const styles = useStyles();

  const [message, setMessage] = useState('');

  const connection = useRef<HubConnection | null>(null);
  const scrollContainer = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<{
    username: string;
    message: string;
    fromMe: boolean;
    color: string;
  }[]>([]);


  useEffect(() => {
    connection.current = new signalR.HubConnectionBuilder()
      .withUrl(WS_URL + "/chatHub")
      .withAutomaticReconnect()
      .build();

    connection.current?.on("ReceiveMessage", (liveId: number, user: string, message: string) => {
      if (liveId !== Number(id)) return;
      let color: string;
      if (colorsByUsername[user]) {
        color = colorsByUsername[username]
      } else {
        color = colors[currentColorIndex];
        currentColorIndex = (currentColorIndex + 1) % colors.length;
        colorsByUsername[user] = color;
      }
      setMessages(prev => [...prev, {
        username: user,
        message,
        fromMe: user === username,
        color
      }])
    });

    async function start() {
      try {
        await connection.current?.start();
        console.log("SignalR Connected.");
      } catch (err) {
        console.log(err);
        connection.current = null;
        setTimeout(start, 5000);
      }
    }

// Start the connection.
    start();
    colorsByUsername[username] = colors[0]

    return () => {
      connection.current?.stop();
      connection.current = null;
      colorsByUsername = {};
      currentColorIndex = 1;
    }
  }, []);

  useEffect(() => {
    scrollContainer.current?.scrollTo(0, scrollContainer.current?.scrollHeight);
  }, [messages.length]);

  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!message.length || disabled || connection.current?.state !== HubConnectionState.Connected) return;
    setDisabled(true);
    await connection.current?.invoke('SendMessage', Number(id), username, message).then(() => {
      console.log('message send');
      setMessage('');
    }).finally(() => setDisabled(false));
  }

  return (
    <div className={styles.chatWrapper}>
      <div className={styles.chatTitle}>
        Live  чат
      </div>
      <div className={styles.chatContent} ref={scrollContainer}>
        {
          messages.map(({username, message, fromMe, color}) => (
            <>
              <div className={styles.chatCircle} style={{backgroundColor: color}}>
                {username.charAt(0)}
              </div>
              <div className={styles.chatUsername}>
                {username}
              </div>
              <div className={styles.chatMessage}>
                {message}
              </div>

            </>

          ))
        }

      </div>
      <div className={styles.chatFooter}>
        <div className={styles.chatItem}>
          <div className={styles.chatCircle} style={{backgroundColor: colors[0]}}>
            {username?.charAt(0)}
          </div>
          <div className={styles.chatUsername}>
            {username}
          </div>
        </div>
        <form className={styles.chatInputGroup} onSubmit={handleSubmit}>
          <div />
          <input
            value={message}
            className={styles.chatInput}
            placeholder={t('Добавить комментарий ...') as string}
            onChange={e => setMessage(e.target.value)}
          />
          <button className={styles.sendButton} disabled={!message.length || disabled}>
            <img  src={sendIcon} className={styles.sendIcon}/>
          </button>
        </form>
      </div>
    </div>
)
}

export default Chat;