import {ReactElement, useState, ChangeEvent, Dispatch, SetStateAction, RefObject} from 'react';
import { useNavigate  } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { 
    Grid,
    TextField,
    Button,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    InputAdornment,
    IconButton,
    Link
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { login } from '../services/apiService';
import Fetch from '../services/fetch';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    root: {
        width: '450px',
        maxWidth: '96%'
    },
    title: {
        '&.MuiTypography-root': {
            margin: "30px 30px 0",
            paddingBottom: "8px",
            fontSize: "20px", 
            textAlign: "center",
            borderBottom: "1px solid rgba(19, 129, 136, 1)",
        }
    },
    formField: {
        margin: "35px 0"
    },
    submitBtn: {
        '&.MuiDialogActions-root': {
            padding: '0 30px',
            margin: '0 0 30px',
            flexDirection: 'column',
            justifyContent: 'center',

            '& .MuiButtonBase-root.MuiButton-root': {
                minWidth: '250px',
                padding: '6px 50px',
                borderRadius: '15px',
                textTransform: 'initial',
                fontSize: '20px',
                
                '&:hover, &:active, &:focus': {
                    background: 'rgba(15, 128, 134, 1)',
                    color: '#fff'
                }
            },

            '& .MuiLink-root.MuiLink-button': {
                marginLeft: 0,
                marginTop: '10px',
                color: 'black',
                textDecorationColor: 'black'
            }
        }
    },
    errorMessage: {
        margin: '0 auto',
        width: 'fit-content',
        marginTop: 5,
        color: '#B93A3A',
    }
});

export type ILoginProps = {
    handleClose: () => void;
    setUserName: Dispatch<SetStateAction<string | null>>;
    handleRefresh: (refreshToken: string) => void;
    timeoutId: RefObject<number | null>;
}

interface IErrors {
    email?: string,
    password?: string,
    errorMsg?: string,
}

const refreshTimeInMs = 15 * 60 * 1000;

const Login = ({ handleClose, setUserName, handleRefresh, timeoutId }: ILoginProps): ReactElement => {
    const { t } = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();
    const locale = localStorage.getItem("lang");

    const [state, setState] = useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState<IErrors>({
    });

    const handleClick = () => {
        navigate(locale?.toLowerCase() + "/contact-us");
        handleClose();
    }
    
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [isShownPass, setIsShownPass] = useState(false);
    const handleClickShowPassword = () => setIsShownPass(!isShownPass);
    const handleMouseDownPassword = () => setIsShownPass(!isShownPass);

    const validateValues = () => {
        const errors: IErrors = {};
        if(!state.email) {
            errors.email = "The field is required";
        }

        if(state.email && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(state.email)) {
            errors.email = "Email is invalid."
        }

        if(!state.password) {
            errors.password = "The field is required";
        }
        setErrors(errors);
        return !Object.keys(errors).length;


    };

    const onChange = async (field: string, value: string) => {
        setState({
            ...state,
            [field]: value
        });
    }

    const handleLogin = async (e: any) => {
        e.preventDefault();
        const user = {
            email: state.email,
            password: state.password,
            rememberMe: true
        };

        const isValid = validateValues();
        if (isValid) {
            setBtnDisabled(true);
            try {
                const response = await login(user);
                Fetch.token = response.accessToken;
                setUserName(response.username);
                handleClose();
                // @ts-ignore
                timeoutId.current = setTimeout(handleRefresh, refreshTimeInMs, response.refreshToken);
                localStorage.setItem('refreshToken', response.refreshToken);
            } catch (e) {
                setErrors({ ...errors, errorMsg: 'Неверный логин или пароль.' });
            } finally {
                setBtnDisabled(false)
            }
        }
    }

    return (
        <Grid container justifyContent="center" data-ui-name="login">
            <Dialog open PaperProps={{ style: { borderRadius: '20px' }   }}>
                <form 
                    noValidate={true}
                    autoComplete="off"
                    onSubmit={handleLogin}
                    className={classes.root}
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'rgba(15, 128, 134, 1)',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <DialogTitle className={classes.title}>{t("Войти")}</DialogTitle>
                    <DialogContent>
                        <Grid className={classes.formField}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="email"
                                label="Логин"
                                type="email"
                                onChange={(e: any) => onChange("email", e.target.value)}
                                helperText={errors.email ? errors.email : ""}
                                error={!!errors.email}
                            />
                        </Grid>

                        <Grid className={classes.formField}>
                            <TextField
                                fullWidth
                                label={t("Пароль")}
                                variant="outlined"
                                id="password"
                                type={isShownPass ? "text" : "password"}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange("password", e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        >
                                        {isShownPass ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    )
                                }}
                                helperText={errors.password ? errors.password : ""}
                                error={!!errors.password}
                            />
                            {errors.errorMsg && <div className={classes.errorMessage}>{t("Неверный логин или пароль")}.</div>}
                        </Grid>

                    </DialogContent>
                    <DialogActions className={classes.submitBtn}>
                        <Button variant="outlined" type="submit" disabled={btnDisabled}>
                            {t("Войти")}
                        </Button>
                        <Link onClick={handleClick} component="button">
                            {t("Забыли пароль?")}
                        </Link>
                    </DialogActions>
                </form>
            </Dialog>
        </Grid>
    );
};

export default Login;

